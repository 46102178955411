import { Dialog, Typography, Stack, Slide, SxProps } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import CloseButton from 'components/ui/buttons/CloseButton';
import useResponsive from 'hooks/useResponsive';

type PropsBase = {
  title: string;
  children: React.ReactNode;
  isOpened: boolean;
  /** @default false */
  disableClose?: boolean;
  /** big - ~700px на пк, полный экран на мобиле; small - и на пк и на мобиле в виде попапа, макс ширина ~400px   */
  size?: 'big' | 'medium' | 'small'; // 'medium' пока не задействован
  sx?: SxProps
};

type Props = PropsBase & {
  closeHandler: VoidFunction;
};

type PropsOld = PropsBase & {
  /** @deprecated */
  setIsOpened: (value: boolean) => void;
  closeHandler?: VoidFunction;
};

export default function PopupContent(props: Props | PropsOld) {
  const { title, children, isOpened, disableClose = false, closeHandler: closeHandlerRaw, size = 'big' } = props;
  const isMobile = useResponsive('down', 'md');

  const closeHandler = () => {
    if (props.hasOwnProperty('setIsOpened')) {
      const oldProps = props as PropsOld;
      if (typeof closeHandlerRaw === 'function') closeHandlerRaw();
      oldProps.setIsOpened(false);
    } else if (closeHandlerRaw) {
      closeHandlerRaw();
    }
  };

  const content = (
    <>
      <Stack direction="row" sx={{ position: 'relative' }}>
        <Typography variant="h6" sx={{ pr: 3 }}>
          {title}
        </Typography>
        {!disableClose && <CloseButton onClick={closeHandler} sx={{ position: 'absolute', top: -10, right: -10 }} title="" />}
      </Stack>
      <Stack spacing={2} sx={{ mt: 3 }}>
        {children}
      </Stack>
    </>
  );

  if (size === 'small') {
    return (
      <Dialog
        TransitionComponent={PopupTransition}
        open={isOpened}
        onClose={closeHandler}
        scroll="paper"
        PaperProps={{ sx: { width: { xs: '100%', md: '400px' }, maxWidth: '400px', mx: 2, p: 3, ...props.sx }, }}
      >
        {content}
      </Dialog>
    );
  }

  return (
    <Dialog
      TransitionComponent={PopupTransition}
      PaperProps={{ sx: { m: '0px !important', px: 3, py: 4, width: isMobile ? '100%' : '720px', ...props.sx }, }}
      fullScreen={isMobile}
      open={isOpened}
      onClose={closeHandler}
      scroll="body"
    >
      {content}
    </Dialog>
  );
}

export const PopupTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
