import { useEffect, useState } from 'react';
import { FormikContextType } from 'formik';
import FunctionUtils from 'utils/Function';
import useDI from 'hooks/useDI';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import AutocompleteMemorized from 'components/ui/forms/AutocompleteMemorized';
import useIsMountedRef from 'hooks/useIsMountedRef';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import NavigateBackendUtils from 'utils/NavigateBackend';
import EntityApiServiceFetchImpl from 'services/entityApi/EntityApiServiceFetchImpl';
import { DYNAMIC_FIELD_RESPONSE_TYPE } from '../../../typings/subEntities/dynamicField.enum';

// TODO Объединить с соседним Radio
export default function DynamicFieldSelectEditState({
  field,
  formState,
  dataSourceOptions,
  areDataSourceOptionsLoading,
  keyProp,
}: {
  field: DynamicField.DynamicFieldSelectField;
  formState: FormikContextType<any>;
  /** Если для селекта требуется передать варианты из вне (например, кастомные филды ордера получают данные из datasource, но добавляют в варианты ещё "выбрать всё") */
  dataSourceOptions?: any[];
  areDataSourceOptionsLoading?: boolean;
  keyProp?: string;
}) {
  const { storageActions, services } = useDI();
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([] as EntityWithName[]);
  const snackbarErrorHandler = useSnackbarErrorHandler();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    setIsLoading(true);
    // Если переданы из вне
    if (typeof areDataSourceOptionsLoading === 'boolean' && dataSourceOptions !== undefined) {
      setIsLoading(areDataSourceOptionsLoading);
      setOptions(dataSourceOptions);
      return;
    }

    const { responseType, responseSource, options } = field.dataSource;
    let { url } = field.dataSource;
    if (url) {
      url = decodeURI(url);
      url = encodeURI(url);
    }

    switch (responseType) {
      case DYNAMIC_FIELD_RESPONSE_TYPE.technicalNameWithName: {
        if (!url) {
          setOptions(options);
          setIsLoading(false);
          break;
        }
        const filter: LocationSearchObject = {
          size: NavigateFrontendUtils.createLocationSearchParam(NavigateBackendUtils.MAX_PAGE_SIZE),
        };
        const path = NavigateFrontendUtils.createURLWithSearchQuery(PATH_BACKEND.root + url, filter);
        services.http
          .fetch(path, { credentials: 'include', headers: EntityApiServiceFetchImpl.getDefaultHeaders() })
          .then((rawResponse) => services.http.parseResponseJson<EntityListData<{ technicalName: string; name: string }>>(rawResponse))
          .then(
            (response) =>
              isMountedRef.current &&
              setOptions(
                response.data.map((i) => {
                  return { id: i.technicalName, name: i.name };
                })
              )
          )
          .catch((error) => snackbarErrorHandler({ error, source: `getNameWithValueForDynamicField (${url})` }))
          .finally(() => isMountedRef.current && setIsLoading(false));
        break;
      }
      case DYNAMIC_FIELD_RESPONSE_TYPE.idWithName: {
        if (!url) {
          setOptions(options);
          setIsLoading(false);
          break;
        }
        const filter: LocationSearchObject = {
          size: NavigateFrontendUtils.createLocationSearchParam(NavigateBackendUtils.MAX_PAGE_SIZE),
        };
        const path = NavigateFrontendUtils.createURLWithSearchQuery(PATH_BACKEND.root + url, filter);
        services.http
          .fetch(path, { credentials: 'include', headers: EntityApiServiceFetchImpl.getDefaultHeaders() })
          .then((rawResponse) => services.http.parseResponseJson<EntityListData<EntityWithName>>(rawResponse))
          .then((response) => isMountedRef.current && setOptions(response.data))
          .catch((error) => snackbarErrorHandler({ error, source: `getIdWithNameForDynamicField (${url})` }))
          .finally(() => isMountedRef.current && setIsLoading(false));
        break;
      }
      case DYNAMIC_FIELD_RESPONSE_TYPE.model: {
        switch (responseSource) {
          case 'USER': {
            if (!url) {
              setOptions(options);
              setIsLoading(false);
              break;
            }

            // TODO тут мы получаем базовых юзеров и не мапим их атрибуты, надо придумать универсальный метод
            const service = storageActions.requestScope.userBase();
            service
              .requestList(
                null,
                { size: NavigateFrontendUtils.createLocationSearchParam(NavigateBackendUtils.MAX_PAGE_SIZE) },
                PATH_BACKEND.root + url
              )
              .then((response) => isMountedRef.current && setOptions(response.data))
              .catch((error) => snackbarErrorHandler({ error, source: 'getUserListForDynamicField' }))
              .finally(() => isMountedRef.current && setIsLoading(false));
            return service.cancelRequests;
          }
          case undefined: {
            break;
          }
          default: {
            FunctionUtils.exhaustiveCheck(responseSource);
          }
        }
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(responseType);
      }
    }
  }, [areDataSourceOptionsLoading, field.technicalName]);

  return (
    <AutocompleteMemorized
      fieldName={field.technicalName}
      label={field.name}
      formState={formState}
      options={options}
      multiple={field.multiple}
      isLoading={isLoading}
      key={keyProp}
    />
  );
}
