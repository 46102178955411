import { TableCell, TableRow } from "@mui/material";
import TextFieldMemorized from "components/ui/forms/TextFieldMemorized";
import { useFormik } from "formik";
import { ChildFormsState } from "hooks/useChildFormArray";
import useDebounce from "hooks/useDebounce";
import useDI from "hooks/useDI";
import useLocales from "hooks/useLocales";
import React, { useState } from "react";
import * as Yup from 'yup'

type OrderSessionNumbersItemProps = {
  shop: Shop | null,
  apartment: OrderApartment | null,
  orderType: EntityWithTenant | null,
  formId: number;
  apartmentsChildFormsArrayRef: ChildFormsState['formsArrayRef']
}

export default function OrderSessionNumbersItem({
  shop,
  apartment,
  orderType,
  formId,
  apartmentsChildFormsArrayRef
}: OrderSessionNumbersItemProps) {
  const { storageActions } = useDI()
  const { translate } = useLocales()
  const [orderNumber, setOrderNumber] = useState('')

  const apartmentNumberLengthErrorText = translate('errors.fieldMustContainOnlyNumbersAndConsistOfCharachters', { lengthRequired: 5 })
  const validationSchema = Yup.object().shape({
    apartmentNumber: Yup.string().matches(/^\d{1,5}$/, apartmentNumberLengthErrorText).required(translate('errors.fieldIsRequired')),
    developerApartmentId: Yup.string().required(translate('errors.fieldIsRequired'))
  })

  const initialValues = {
    apartmentNumber: apartment?.apartmentNumber ?? '',
    developerApartmentId: apartment?.developerApartmentId ?? ''
  }

  const formState = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => values
  })

  const { apartmentNumber, developerApartmentId } = formState.values

  useDebounce({
    delay: 500,
    instantRequest: true,
    dependencies: [apartmentNumber, developerApartmentId, shop, orderType, formState.errors],
    action: async () => {
      if (!shop || !orderType || !apartmentNumber.toString().length || !developerApartmentId.length || Object.keys(formState.errors).length) {
        setOrderNumber('')
        return
      }
      await formState.validateForm({ apartmentNumber, developerApartmentId })
      let updatedApartmentNumber = apartmentNumber
      if (apartmentNumber.length < 5) {
        for (let i = apartmentNumber.length; i < 5; i++) {
          updatedApartmentNumber = '0' + updatedApartmentNumber
        }
      }
      if (!Object.keys(formState.errors).length) {
        const orderNumber = await storageActions.models.orderSession.generateOrderNumber({
          apartmentNumber: updatedApartmentNumber,
          developerApartmentId,
          dealerNumber: shop.dealerNumber,
          shopNumber: shop.shopNumber,
          orderType: orderType.name
        })
        setOrderNumber(orderNumber)
      }
    }
  })

  apartmentsChildFormsArrayRef.current[formId] = formState

  return <TableRow sx={{ '& td.MuiTableCell-root': { py: 0 } }}>
    <TableCell>{orderNumber}</TableCell>
    <TableCell>{shop?.dealerNumber ?? ''}</TableCell>
    <TableCell>{shop?.shopNumber ?? ''}</TableCell>
    <TableCell sx={{ width: '20%', background: '#E9FCD4' }}>
      <TextFieldMemorized
        fieldName='apartmentNumber'
        formState={formState}
        sx={{
          maxWidth: '140px',
          '& .MuiOutlinedInput-root': {
            fontSize: { xs: '12px', md: '14px' },
            '& fieldset': {
              border: 'none',
            },
          },
        }}
      />
    </TableCell>
    <TableCell sx={{ width: '20%', background: '#E9FCD4' }}>
      <TextFieldMemorized
        fieldName='developerApartmentId'
        formState={formState}
        sx={{
          maxWidth: '140px',
          '& .MuiOutlinedInput-root': {
            fontSize: { xs: '12px', md: '14px' },
            '& fieldset': {
              border: 'none',
            },
          },
        }}
      />
    </TableCell>
  </TableRow>
}