import React, { useMemo } from 'react';
import PredefinedVariableBlock from 'components/_dashboardPagesFeatures/promotionsConfig/predefined/PredefinedVariableBlock';
import PopupAction from 'components/ui/popups/PopupAction';
import useLocales from 'hooks/useLocales';
import { ChildFormsState } from 'hooks/useChildForms';
import PredefinedDictionaryVariableEditPopup from 'components/_dashboardPagesFeatures/promotionsConfig/predefined/PredefinedDictionaryVariableEditPopup';
import { PromotionsConfigResponseDTO } from 'typings/dto/promotionsConfig';
import EmptyListBlock from 'components/features/list/EmptyListBlock';
import { ListIcon, PlusIcon } from 'components/ui/icons';
import FormUtils from 'utils/Form';
import MenuButton from 'components/ui/buttons/MenuButton';
import { useSelector } from 'storage';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';

const modelPath: keyof PromotionsConfigResponseDTO['promotionsConfig'] = 'predefined';

type Props = {
  isPopupOpened: boolean;
  popupCloseHandler: () => void;
  variablesList: PromotionsPredefined.PromotionsPredefinedUnknown[] | undefined;
  varsChildForms: ChildFormsState;
  rerenderPageCallback: VoidFunction;
  setAnyDirty: (val: boolean) => void;
  setToDeleteIndex: (index: string | null) => void;
  popupOpenHandler: (index?: string) => void;
  toOpenIndex?: string;
  dictionaryList: Dictionary[];
  closeDeleteHandler: VoidFunction;
  deletePredefinedHandler: VoidFunction;
  setIsDeletePopupOpened: (val: boolean) => void;
  isDeletePopupOpened: boolean;
  isEditable?: boolean;
  parentModelPath: string;
  configError: Record<string, string>;
  anyDirty?: boolean
};

export default function VariablesEditBlock({
  isPopupOpened,
  popupCloseHandler,
  variablesList,
  varsChildForms,
  rerenderPageCallback,
  popupOpenHandler,
  toOpenIndex,
  dictionaryList,
  setToDeleteIndex,
  setAnyDirty,
  closeDeleteHandler,
  deletePredefinedHandler,
  setIsDeletePopupOpened,
  isDeletePopupOpened,
  isEditable = true,
  parentModelPath,
  configError,
  anyDirty,
}: Props) {
  const { translate } = useLocales();
  const { permissions } = useSelector(state => state.auth)

  const dictionaryMap: Record<string, Dictionary> = useMemo(() => {
    return dictionaryList.reduce((acc: Record<string, Dictionary>, item: Dictionary) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, []);

  const menu: MenuItemData[] = useMemo(() => {
    if (permissions.can(MODEL_PERMISSION.CAN_UPDATE_PROMOTIONS)) {
      return [
        {
          title: translate('pages.promotionsVariablesEdit.menu.addFromDictionary'),
          handler: () => popupOpenHandler(),
        },
        {
          title: translate('pages.promotionsVariablesEdit.menu.addTextVariable'),
          handler: FormUtils.addChildFormHandlerFactory(varsChildForms.formsMapRef.current, rerenderPageCallback),
        }
      ]
    }
    return []
  }, [])

  return (
    <>
      {varsChildForms.formsKeys.length && variablesList
        ? varsChildForms.formsKeys.map((formId, formIndex) => {
          return (
            <PredefinedVariableBlock
              key={formId}
              formId={formId}
              formsStatesMapRef={varsChildForms.formsMapRef}
              initialState={variablesList[formIndex]}
              formIndex={formIndex}
              formChangedCallback={() => setAnyDirty(true)}
              deletePredefinedHandler={(formId: string) => {
                setToDeleteIndex(formId);
                setIsDeletePopupOpened(true);
              }}
              popupOpenHandler={popupOpenHandler}
              dictionaryMap={dictionaryMap}
              isEditable={isEditable}
              parentModelPath={parentModelPath + '.' + modelPath}
              configError={configError}
            />
          );
        })
        : !anyDirty && <EmptyListBlock
          icon={<ListIcon />}
          title={translate('pages.promotionsVariablesEdit.emptyTitle')}
          subtitle={translate('pages.promotionsVariablesEdit.emptySubtitle')}
        >
          <MenuButton
            options={menu}
            customIcon={<PlusIcon />}
            title={translate('pages.promotionsVariablesEdit.addVariable')}
            styleVariant='text'
            variant='full'
          />
        </EmptyListBlock>
      }

      {isPopupOpened && (
        <PredefinedDictionaryVariableEditPopup
          isOpened={isPopupOpened}
          rerenderPageCallback={rerenderPageCallback}
          formsStatesMapRef={varsChildForms.formsMapRef}
          closeHandler={popupCloseHandler}
          formId={toOpenIndex}
          formChangedCallback={() => setAnyDirty(true)}
          dictionaryMap={dictionaryMap}
        />
      )}

      <PopupAction
        isOpened={isDeletePopupOpened}
        closeHandler={closeDeleteHandler}
        actionHandler={deletePredefinedHandler}
        title={translate('pages.promotionsCategoriesEdit.deleteTitle')}
      />
    </>
  );
}
