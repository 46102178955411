import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD as P, PAGE_TYPE, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class ServiceCatalogRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(P.service.root);

  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) => this.getDBPath(P.service.root, params.enterpriseId, PAGE_TYPE.list);

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(
      P.service.root,
      params.enterpriseId,
      PAGE_TYPE.list,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(
      P.service.root,
      params.enterpriseId,
      PAGE_TYPE.list,
      params.serviceId,
      PAGE_TYPE.edit
    );

}
