import React from 'react';
import { useSelector } from 'storage';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import FormProvider from 'components/ui/forms/FormProvider';
import UploadFilesMemorized from 'components/ui/forms/files/UploadFilesMemorized';
import CheckboxMemorized from 'components/ui/forms/CheckboxMemorized';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import * as Yup from 'yup';
import IFileService from 'services/file/IFileService';
import { useFormik } from 'formik';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useDI from 'hooks/useDI';
import FileUtils from 'utils/File';

type Props = {
  closeHandler: VoidFunction;
  submitHandler: (file: File, force: boolean) => Promise<void>;
};

function ImportDataPopup({ closeHandler, submitHandler }: Props) {
  const { currentUser } = useSelector((state) => state.auth);
  const handleFormErrors = useSnackbarErrorHandler();
  const { services } = useDI();
  const { translate } = services.language;
  const isMountedRef = useIsMountedRef();

  const validationSchema = Yup.object().shape({
    files: Yup.array(),
    force: Yup.boolean(),
  });
  const initialValues = {
    files: [] as IFileService.FileForUploaderWOData[],
    force: false,
  };

  const formState = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!currentUser) {
          return;
        }

        setSubmitting(true);
        const { files, force } = values;
        if (files.length !== 1) {
          throw Error('One file required');
        }
        await submitHandler(files[0], force);
        closeHandler();

        if (isMountedRef.current) closeHandler();
      } catch (error) {
        handleFormErrors({ error, callback: () => setSubmitting(false) });
      }
    },
  });

  const { isSubmitting } = formState;

  return (
    <>
      <FormProvider formState={formState} fullWidth>
        <UploadFilesMemorized allowedFormats={[FileUtils.FILE_TYPE.json]} fieldName="files" formState={formState} multiple={false} />
        <CheckboxMemorized
          variant="switch"
          fieldName="force"
          formState={formState}
          label={<>{translate('entities.recommendations.importForce')}</>}
        />
        <ConfirmButtonGroup cancelHandler={closeHandler} isLoading={isSubmitting} />
      </FormProvider>
    </>
  );
}

export default React.memo(ImportDataPopup, () => true);
