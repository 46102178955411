import React, { MutableRefObject, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import useLocales from 'hooks/useLocales';
import FormProvider from 'components/ui/forms/FormProvider';
import { Button, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { EmailIcon } from 'components/ui/icons';
import { EMAIL_VALIDATION_REGEXP } from 'configs/vars';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import CheckboxMemorized from 'components/ui/forms/CheckboxMemorized';
import useDI from 'hooks/useDI';
import { OrderContractSessionClientDataRequestDTO, OrderSessionClientDataDTO } from 'typings/dto/order';
import useChildForms from 'hooks/useChildForms';
import EditPhoneItemForm from 'components/_dashboardPagesFeatures/order/edit/EditPhoneItemForm';
import useRerenderComponent from 'hooks/useRerenderComponent';
import PopupAction from 'components/ui/popups/PopupAction';
import FormUtils from 'utils/Form';

type FormStateInitialType = {
  name: any;
  clientsEmail: any;
  fullData: any;
  registrationAddress: any;
  vip: any;
  representedBy: any;
  entityName: any;
  correspondentAccount: any;
  bankName: any;
  bik: any;
  checkingAccount: any;
  ogrn: any;
  kpp: any;
  additionalContactInfo: any;
};

type Props = {
  orderSession: OrderContractSessionData;
  enterprise: Enterprise;
  cancelHandler: VoidFunction;
  submitHandler: VoidFunction;
  cancelSessionHandler: VoidFunction;
  parentLinkedOrder?: Order;
  customerFormStateValuesRef: MutableRefObject<FormStateInitialType | null>;
  customerPhonesFormStateValuesRef: MutableRefObject<{ number: string }[] | null>;
};

function OrderAddFromSessionClientDataForm({
  orderSession,
  submitHandler,
  cancelHandler,
  cancelSessionHandler,
  parentLinkedOrder,
  customerFormStateValuesRef,
  customerPhonesFormStateValuesRef,
}: Props) {
  const handleFormErrors = useSnackbarErrorHandler();
  const { translate } = useLocales();
  const { storageActions } = useDI();
  const phonesChildForms = useChildForms(
    customerPhonesFormStateValuesRef.current?.length ||
      orderSession.customer.phones.length ||
      parentLinkedOrder?.customer.phones.length ||
      1
  );

  const [isLegalEntity, setIsLegalEntity] = useState<boolean>(orderSession.customer.customerLegalEntity !== undefined);
  const [isCancelBlockOpened, setIsCancelBlockOpened] = useState(false);

  const rerenderPageCallback = useRerenderComponent();

  const [validationSchema, initialValues] = useMemo(() => {
    const emailIsNotValidText = translate('errors.emailIsNotValid');
    const fieldIsRequiredText = translate('errors.fieldIsRequired');
    const validationSchema = Yup.object().shape({
      clientsEmail: Yup.string().matches(EMAIL_VALIDATION_REGEXP, emailIsNotValidText).required(fieldIsRequiredText),
      name: Yup.string().trim(fieldIsRequiredText).required(fieldIsRequiredText),
      registrationAddress: Yup.string().trim(fieldIsRequiredText),
      fullData: Yup.string().trim(fieldIsRequiredText),
      representedBy: Yup.string().trim(fieldIsRequiredText),
      additionalContactInfo: Yup.string().trim(fieldIsRequiredText),
      entityName: Yup.string().trim(fieldIsRequiredText),
      correspondentAccount: Yup.string().trim(fieldIsRequiredText),
      bankName: Yup.string().trim(fieldIsRequiredText),
      bik: Yup.string().trim(fieldIsRequiredText),
      checkingAccount: Yup.string().trim(fieldIsRequiredText),
      ogrn: Yup.string().trim(fieldIsRequiredText),
      kpp: Yup.string().trim(fieldIsRequiredText),
      vip: Yup.mixed(),
    });

    const initialValues: FormStateInitialType = customerFormStateValuesRef.current
      ? customerFormStateValuesRef.current
      : {
          name: orderSession.customer.name || parentLinkedOrder?.customer.name || '',

          clientsEmail: orderSession.customer.email || parentLinkedOrder?.customer.email || '',
          fullData: orderSession.customer.passport?.fullData || parentLinkedOrder?.customer.passport?.series || '',
          registrationAddress: orderSession.customer.registrationAddress || parentLinkedOrder?.customer.registrationAddress || '',
          vip: orderSession.customer.vip || parentLinkedOrder?.customer.vip || false,
          representedBy:
            orderSession.customer.customerLegalEntity?.representedBy ||
            parentLinkedOrder?.customer.customerLegalEntity?.representedBy ||
            '',
          entityName:
            orderSession.customer.customerLegalEntity?.entityName || parentLinkedOrder?.customer.customerLegalEntity?.entityName || '',
          correspondentAccount:
            orderSession.customer.customerLegalEntity?.correspondentAccount ||
            parentLinkedOrder?.customer.customerLegalEntity?.correspondentAccount ||
            '',
          bankName: orderSession.customer.customerLegalEntity?.bankName || parentLinkedOrder?.customer.customerLegalEntity?.bankName || '',
          bik: orderSession.customer.customerLegalEntity?.bik || parentLinkedOrder?.customer.customerLegalEntity?.bik || '',
          checkingAccount:
            orderSession.customer.customerLegalEntity?.checkingAccount ||
            parentLinkedOrder?.customer.customerLegalEntity?.checkingAccount ||
            '',
          ogrn: orderSession.customer.customerLegalEntity?.ogrn || parentLinkedOrder?.customer.customerLegalEntity?.ogrn || '',
          kpp: orderSession.customer.customerLegalEntity?.kpp || parentLinkedOrder?.customer.customerLegalEntity?.kpp || '',
          additionalContactInfo: orderSession.customer.additionalContactInfo || parentLinkedOrder?.customer.additionalContactInfo || '',
        };
    return [validationSchema, initialValues];
  }, [orderSession?.id]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const {
          name,
          clientsEmail,
          fullData,
          registrationAddress,
          vip,
          representedBy,
          entityName,
          correspondentAccount,
          bankName,
          bik,
          checkingAccount,
          ogrn,
          kpp,
          additionalContactInfo,
        } = values;

        if (!name) {
          throw new Error('Data is missing');
        }
        const legalEntity = isLegalEntity
          ? {
              entityName,
              checkingAccount,
              correspondentAccount,
              bankName,
              bik,
              ogrn,
              kpp,
              representedBy,
            }
          : undefined;
        const phones = await phonesChildForms.submitForms<string>();
        if (new Set(phones).size !== phones.length) {
          throw new Error(translate('errors.orderPhonesAreNotUnique'));
        }
        const customer: OrderSessionClientDataDTO = {
          technicalName: orderSession.customer.technicalName,
          balance: orderSession.customer.balance,
          phones: phones.filter((p) => p != null),
          name: name,
          email: clientsEmail,
          passport: {
            fullData,
          },
          additionalContactInfo: additionalContactInfo,
          registrationAddress,
          vip,
          customerLegalEntity: legalEntity,
        };

        const dto: OrderContractSessionClientDataRequestDTO = {
          customer: customer,
        };

        await storageActions.models.orderSession.updateClientData(orderSession.id, dto);
        await storageActions.models.orderSession.getOrderSession(orderSession.id);
        submitHandler();
      } catch (error) {
        handleFormErrors({ error, callback: () => setSubmitting(false) });
      }
    },
  });

  customerFormStateValuesRef.current = formState.values;
  const savePhones = async () => {
    const phones = await phonesChildForms.submitForms<string>().then((n) => {
      return n.map((number) => {
        return { number: number };
      });
    });
    customerPhonesFormStateValuesRef.current = phones;
  };

  return (
    <Stack spacing={3}>
      <FormProvider formState={formState}>
        <Typography variant="h6">{translate('pages.orderSessionEdit.clientData')}</Typography>
        <RadioGroup value={isLegalEntity} onChange={(event) => setIsLegalEntity(event.target.value === 'true')}>
          <FormControlLabel
            value={false}
            label={translate('entities.orderSession.client.individualRadio')}
            control={<Radio size="small" />}
          />
          <FormControlLabel
            value={true}
            label={translate('entities.orderSession.client.legalEntityRadio')}
            control={<Radio size="small" />}
          />
        </RadioGroup>
        <TextFieldMemorized fieldName="name" label={translate('entities.orderSession.client.name')} formState={formState} />
      </FormProvider>
      {phonesChildForms.formsKeys.map((formId, index) => (
        <EditPhoneItemForm
          key={formId}
          formId={formId}
          formsStatesMapRef={phonesChildForms.formsMapRef}
          rerenderParent={rerenderPageCallback}
          initialState={
            customerPhonesFormStateValuesRef.current !== null && customerPhonesFormStateValuesRef.current.length > 0
              ? customerPhonesFormStateValuesRef.current[index]
              : orderSession.customer?.phones.length > 0
              ? orderSession.customer?.phones[index]
              : parentLinkedOrder?.customer?.phones[index]
          }
        />
      ))}
      <TextFieldMemorized fieldName="clientsEmail" formState={formState} label={translate('fields.email')} startAdornment={<EmailIcon />} />

      <TextFieldMemorized
        fieldName="registrationAddress"
        label={translate('entities.orderSession.registrationAddress')}
        formState={formState}
      />
      <TextFieldMemorized
        fieldName="additionalContactInfo"
        label={translate('entities.orderSession.additionalContactInfo')}
        formState={formState}
      />
      <CheckboxMemorized variant="switch" fieldName={'vip'} formState={formState} label={translate('entities.orderSession.vip')} />
      <Typography variant="h6">{translate('pages.orderSessionEdit.passportData')}</Typography>
      <TextFieldMemorized fieldName="fullData" label={translate('entities.orderSession.passport.fullData')} formState={formState} />

      {isLegalEntity && (
        <>
          <Typography variant="h6">{translate('pages.orderSessionEdit.clientLegalEntityData')}</Typography>

          <TextFieldMemorized
            fieldName="representedBy"
            label={translate('entities.orderSession.legalEntity.representedBy')}
            formState={formState}
          />
          <TextFieldMemorized
            fieldName="entityName"
            label={translate('entities.orderSession.legalEntity.entityName')}
            formState={formState}
          />
          <TextFieldMemorized
            fieldName="correspondentAccount"
            label={translate('entities.orderSession.legalEntity.correspondentAccount')}
            formState={formState}
          />
          <TextFieldMemorized fieldName="bankName" label={translate('entities.orderSession.legalEntity.bankName')} formState={formState} />
          <TextFieldMemorized fieldName="bik" label={translate('entities.orderSession.legalEntity.bik')} formState={formState} />
          <TextFieldMemorized
            fieldName="checkingAccount"
            label={translate('entities.orderSession.legalEntity.checkingAccount')}
            formState={formState}
          />
          <TextFieldMemorized fieldName="ogrn" label={translate('entities.orderSession.legalEntity.ogrn')} formState={formState} />
          <TextFieldMemorized fieldName="kpp" label={translate('entities.orderSession.legalEntity.kpp')} formState={formState} />
        </>
      )}
      <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="baseline">
        <ConfirmButtonGroup
          submitText={translate('buttons.next')}
          cancelText={translate('buttons.back')}
          isLoading={false}
          submitHandler={FormUtils.submitParentFormWithChildrenHandlerFactory(formState, [
            phonesChildForms.formsMapRef.current,
          ])}
          cancelHandler={async () => {
            await savePhones();
            cancelHandler();
          }}
        />
        <Button variant="outlined" color="error" onClick={() => setIsCancelBlockOpened(true)}>
          {translate('buttons.cancel')}
        </Button>
      </Stack>
      <PopupAction
        title={translate(`pages.orderAdd.cancelSessionTitle`)}
        isOpened={isCancelBlockOpened}
        actionHandler={cancelSessionHandler}
        closeHandler={() => setIsCancelBlockOpened(false)}
      >
        {translate('pages.orderAdd.cancelSessionText')}
      </PopupAction>
    </Stack>
  );
}

export default React.memo(OrderAddFromSessionClientDataForm, () => true);
