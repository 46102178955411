import { ButtonProps } from '@mui/material';
import { InfoIcon } from '../icons';
import IconButtonStyled from './IconButtonStyled';


export default function InfoIconButton({ title, color, ...rest }: ButtonProps) {
  return (
    <IconButtonStyled title={title} {...rest}>
      <InfoIcon />
    </IconButtonStyled>
  );
}
