import * as Yup from 'yup';
import ILanguageService from 'services/language/ILanguageService';
import { DYNAMIC_FIELD_RESPONSE_TYPE, DYNAMIC_FIELD_TYPE } from 'typings/subEntities/dynamicField.enum';
import { MixedSchema } from 'yup/lib/mixed';
import IFileService from 'services/file/IFileService';
import FunctionUtils from 'utils/Function';
import EntityUtils from 'utils/Entity';

export default class DynamicFieldUtils {
  /** Возвращает законченную схему для динамических полей, пригодную для формика */
  public static getValidationScheme = (
    fields: DynamicField[],
    languageService: ILanguageService,
    /** На фронте есть кейсы где при работе с DF валидацию надо показать, но не запрещать сабмитить */
    disableValidationSubmitBlock?: boolean
  ): Yup.ObjectSchema<any> => {
    const subScheme = this.getValidationSubScheme(fields, languageService, disableValidationSubmitBlock);
    return Yup.object().shape(subScheme);
  };


  public static getValidationAddFormSubScheme = (
    fields: Order.CustomField[],
    languageService: ILanguageService,
    disableValidationSubmitBlock?: boolean
  ): Record<string, MixedSchema<any, any, any>> => {
    return fields.reduce(
      (acc, c) => ({ ...acc, ...this.getFieldOrderAddFormValidationSubScheme(c, languageService, disableValidationSubmitBlock) }),
      {} as Record<string, MixedSchema<any, any, any>>
    );
  };

  public static getFieldOrderAddFormValidationSubScheme = (
    field: Order.CustomField,
    languageService: ILanguageService,
    disableValidationSubmitBlock?: boolean
  ): Record<string, MixedSchema<any, any, any>> => {
    const dynamicField = field.dynamicField
    const subScheme: Record<string, MixedSchema<any, any, any>> = {};
    const fieldIsRequiredText = languageService.translate('errors.fieldIsRequired');
    const maximumArrayLengthExceededText = languageService.translate('errors.maximumArrayLengthExceeded');

    switch (dynamicField.type) {
      case DYNAMIC_FIELD_TYPE.file: {
        // Нужно чекать два поля - старые файлы и новые
        subScheme[dynamicField.technicalName] = Yup.array();
        subScheme[dynamicField.technicalName + '_old'] = Yup.array();

        if (!dynamicField.multiple) {
          subScheme[dynamicField.technicalName + '_old'] = (subScheme[dynamicField.technicalName + '_old'] as ReturnType<typeof Yup.array>).test({
            message: maximumArrayLengthExceededText + ' (1)',
            test: (v, context) => (v!.length + context.parent[dynamicField.technicalName]!.length) <= 1,
          });
        }

        if (dynamicField.required && !disableValidationSubmitBlock) {
          subScheme[dynamicField.technicalName + '_old'] = (subScheme[dynamicField.technicalName + '_old'] as ReturnType<typeof Yup.array>).test({
            message: fieldIsRequiredText,
            test: (v, context) => {
              if (field.showInSessionByOrderType[context.parent?.type?.technicalName]) return Boolean(v!.length + context.parent[dynamicField.technicalName]!.length)
              return true
            },
          });
        }
        return subScheme;
      }
      // TODO нет учета multiple
      case DYNAMIC_FIELD_TYPE.number:
      case DYNAMIC_FIELD_TYPE.textarea:
      case DYNAMIC_FIELD_TYPE.text: {
        subScheme[dynamicField.technicalName] = Yup.string();
        break;
      }
      // TODO нет учета multiple
      case DYNAMIC_FIELD_TYPE.checkbox:
      case DYNAMIC_FIELD_TYPE.switch: {
        subScheme[dynamicField.technicalName] = Yup.boolean();
        break;
      }
      case DYNAMIC_FIELD_TYPE.date: {
        // Спец поля
        return subScheme;
      }
      case DYNAMIC_FIELD_TYPE.visitDate: {
        // Спец поля
        return subScheme;
      }
      case DYNAMIC_FIELD_TYPE.radioGroup:
      case DYNAMIC_FIELD_TYPE.select: {
        if (dynamicField.multiple) {
          if (dynamicField.required) subScheme[dynamicField.technicalName] = Yup.array().min(1, fieldIsRequiredText);
          else subScheme[dynamicField.technicalName] = Yup.array();
        } else {
          subScheme[dynamicField.technicalName] = Yup.mixed();
        }
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(dynamicField);
      }
    }

    if (dynamicField.required && !disableValidationSubmitBlock) {
      subScheme[dynamicField.technicalName] = subScheme[dynamicField.technicalName].test({
        message: fieldIsRequiredText,
        test: (v, context) => {
          if (field.showInSessionByOrderType[context.parent?.type?.technicalName]) {
            if (Array.isArray(v)) return Boolean(v.length)
            return v !== undefined && v !== null
          }
          return true
        }
      })
    }
    return subScheme;

  };


  /** Возвращает схему для динамических полей, которую потребуется обернуть в Yup.object().shape(); сделано, если нужно добавить ещё каких-то полей */
  public static getValidationSubScheme = (
    fields: DynamicField[],
    languageService: ILanguageService,
    disableValidationSubmitBlock?: boolean
  ): Record<string, MixedSchema<any, any, any>> => {
    return fields.reduce(
      (acc, c) => ({ ...acc, ...this.getFieldValidationSubScheme(c, languageService, disableValidationSubmitBlock) }),
      {} as Record<string, MixedSchema<any, any, any>>
    );
  };

  private static getFieldValidationSubScheme = (
    field: DynamicField,
    languageService: ILanguageService,
    disableValidationSubmitBlock?: boolean
  ): Record<string, MixedSchema<any, any, any>> => {
    const subScheme: Record<string, MixedSchema<any, any, any>> = {};
    const fieldIsRequiredText = languageService.translate('errors.fieldIsRequired');
    const maximumArrayLengthExceededText = languageService.translate('errors.maximumArrayLengthExceeded');
    switch (field.type) {
      case DYNAMIC_FIELD_TYPE.file: {
        // Нужно чекать два поля - старые файлы и новые
        subScheme[field.technicalName] = Yup.array();
        subScheme[field.technicalName + '_old'] = Yup.array();

        if (!field.multiple) {
          subScheme[field.technicalName + '_old'] = (subScheme[field.technicalName + '_old'] as ReturnType<typeof Yup.array>).test({
            message: maximumArrayLengthExceededText + ' (1)',
            test: (v, context) => v!.length + context.parent[field.technicalName]!.length <= 1,
          });
        }

        if (field.required && !disableValidationSubmitBlock) {
          subScheme[field.technicalName + '_old'] = (subScheme[field.technicalName + '_old'] as ReturnType<typeof Yup.array>).test({
            message: fieldIsRequiredText,
            test: (v, context) => Boolean(v!.length + context.parent[field.technicalName]!.length),
          });
        }
        break;
      }
      // TODO нет учета multiple
      case DYNAMIC_FIELD_TYPE.number:
      case DYNAMIC_FIELD_TYPE.textarea:
      case DYNAMIC_FIELD_TYPE.text: {
        subScheme[field.technicalName] = Yup.string();
        break;
      }
      // TODO нет учета multiple
      case DYNAMIC_FIELD_TYPE.checkbox:
      case DYNAMIC_FIELD_TYPE.switch: {
        subScheme[field.technicalName] = Yup.boolean();
        break;
      }
      case DYNAMIC_FIELD_TYPE.date: {
        // Спец поля
        return subScheme;
      }
      case DYNAMIC_FIELD_TYPE.visitDate: {
        // Спец поля
        return subScheme;
      }
      case DYNAMIC_FIELD_TYPE.radioGroup:
      case DYNAMIC_FIELD_TYPE.select: {
        if (field.multiple) {
          if (field.required) subScheme[field.technicalName] = Yup.array().min(1, fieldIsRequiredText);
          else subScheme[field.technicalName] = Yup.array();
        } else {
          subScheme[field.technicalName] = Yup.mixed();
        }
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(field);
      }
    }

    if (field.required && !disableValidationSubmitBlock) {
      subScheme[field.technicalName] = subScheme[field.technicalName].required(fieldIsRequiredText);
    }
    return subScheme;
  };

  /** @param configs если требуется отобразить все поля из конфигов, но со значениями из заполненных */
  public static getInitialState = (fields: DynamicField[], configs?: DynamicField[]): Record<string, any> => {
    if (configs) {
      fields = EntityUtils.filterDuplicates(configs, fields);
    }
    return fields.reduce((acc, field) => this.setFieldInitialValue(field, acc), {} as Record<string, any>);
  };

  /** @returns мутирует входящий объект, возвращает его же */
  public static setFieldInitialValue = (field: DynamicField, initialState: Record<string, any>): Record<string, any> => {
    switch (field.type) {
      case DYNAMIC_FIELD_TYPE.file: {
        initialState[field.technicalName + '_old'] = field.values;
        initialState[field.technicalName] = [] as IFileService.FileForUploaderUnknownData[];
        break;
      }
      // TODO нет учета multiple
      case DYNAMIC_FIELD_TYPE.number:
      case DYNAMIC_FIELD_TYPE.textarea:
      case DYNAMIC_FIELD_TYPE.text: {
        initialState[field.technicalName] = field.values?.join('\n') || '';
        break;
      }
      case DYNAMIC_FIELD_TYPE.checkbox:
      case DYNAMIC_FIELD_TYPE.switch: {
        initialState[field.technicalName] = field.values[0] || false;
        break;
      }
      case DYNAMIC_FIELD_TYPE.radioGroup:
      case DYNAMIC_FIELD_TYPE.select: {
        const { responseType } = field.dataSource;
        switch (responseType) {
          case DYNAMIC_FIELD_RESPONSE_TYPE.technicalNameWithName:
          case DYNAMIC_FIELD_RESPONSE_TYPE.idWithName:
          case DYNAMIC_FIELD_RESPONSE_TYPE.model: {
            initialState[field.technicalName] = field.multiple ? field.values : field.values[0] || null;
            break;
          }
          default: {
            FunctionUtils.exhaustiveCheck(responseType);
          }
        }
        break;
      }
      case DYNAMIC_FIELD_TYPE.date:
      case DYNAMIC_FIELD_TYPE.visitDate: {
        // Спец поля
        return initialState;
      }
      default: {
        FunctionUtils.exhaustiveCheck(field);
      }
    }
    return initialState;
  };
}
