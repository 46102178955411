import { TableCell, TableRow } from "@mui/material";
import TableVariant1 from "components/ui/tables/TableVariant1";
import { ChildFormsState } from "hooks/useChildFormArray";
import useLocales from "hooks/useLocales";
import OrderSessionNumbersItem from "./OrderSessionNumbersItem";
import React from "react";

type OrderSessionContractsBlockProps = {
  apartments: OrderApartment[],
  apartmentsChildForms: ChildFormsState,
  shop: Shop | null;
  orderType: EntityWithTenant | null;
}

export default function OrderSessionContractsBlock({ apartments, shop, orderType, apartmentsChildForms }: OrderSessionContractsBlockProps) {
  return <TableVariant1 header={<TableHeader />}>
    {apartmentsChildForms.formsArrayRef.current.map((apartment, index) => (
      <OrderSessionNumbersItem
        apartmentsChildFormsArrayRef={apartmentsChildForms.formsArrayRef}
        apartment={apartments[index]}
        key={index}
        formId={index}
        shop={shop}
        orderType={orderType}
      />))}

  </TableVariant1>
}

function TableHeader() {
  const { translate } = useLocales()
  return <>
    <colgroup>
      <col style={{ width: '20%' }} span={5} />
    </colgroup>
    <TableRow>
      <TableCell>{translate('pages.orderAdd.finalNumber')}</TableCell>
      <TableCell>{translate('pages.orderAdd.dealerNumber')}</TableCell>
      <TableCell>{translate('pages.orderAdd.buildingCaseNumber')}</TableCell>
      <TableCell>{translate('pages.orderAdd.flatNumber')}</TableCell>
      <TableCell>{translate('pages.orderAdd.flatCode')}</TableCell>
    </TableRow>
  </>
}