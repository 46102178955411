import { CSRF_COOKIE_NAME, CSRF_HEADER_NAME } from 'configs/vars';
import IHttpService from 'services/http/IHttpService';
import CookieUtils from 'utils/Cookie';
import FetchError from 'utils/errors/FetchError';
import UnauthorizedError from 'utils/errors/UnauthorizedError';
import IEntityApiService from './IEntityApiService';
import ILanguageService from 'services/language/ILanguageService';
import ForbiddenError from '../../utils/errors/ForbiddenError';

export default class EntityApiServiceFetchImpl implements IEntityApiService {
  constructor(private httpService: IHttpService, private languageService: ILanguageService) {}

  public static readonly getCSRFToken = () => CookieUtils.getNamedCookie(CSRF_COOKIE_NAME) || '';

  public static readonly getDefaultHeaders = () => ({
    'Content-Type': 'application/json',
    [CSRF_HEADER_NAME]: this.getCSRFToken(),
  });

  // Get

  /** @throws `BackendResponseError` */
  public get = <T>(url: string, options?: RequestInit): Promise<T> => {
    console.debug('[Fetch]: GET', url);
    return this.httpService.fetch(url, options).then(this.parseResponse).then(this.parseResponsePayload);
  };

  /** @throws `BackendResponseError` */
  public getWithCredentials = <T>(url: string): Promise<T> => {
    return this.get(url, {
      method: 'GET',
      credentials: 'include',
    });
  };
  /** @throws `BackendResponseError` */
  public getRaw = (url: string, options?: RequestInit): Promise<Response> => {
    console.debug('[Fetch]: GET', url);
    return this.httpService.fetch(url, options);
  };

  /** @throws `BackendResponseError` */
  public getRawWithCredentials = (url: string): Promise<Response> => {
    return this.getRaw(url, {
      method: 'GET',
      credentials: 'include',
    });
  };

  /** @throws `BackendResponseError` */
  public getList = <T>(url: string, options?: RequestInit): Promise<EntityListData<T>> => {
    console.debug('[Fetch]: GET', url);
    return this.httpService.fetch(url, options).then(this.parseResponse).then(this.parseResponsePayloadWithPaging);
  };

  /** @throws `BackendResponseError` */
  public getListWithCredentials = <T>(url: string, options?: RequestInit): Promise<EntityListData<T>> => {
    return this.getList(url, {
      method: 'GET',
      credentials: 'include',
      ...options,
    });
  };

  /** @throws `BackendResponseError` */
  public getListWithIdAndNamesOnly = <T>(url: string): Promise<EntityListData<T>> => {
    return this.getList(url, { method: 'GET', credentials: 'include' }); // headers: { 'Content-Type': 'application/vnd.clicker.short+json' } // перестало работать на беке
  };

  // Post

  /** @throws `BackendResponseError` */
  public post = <T>(url: string, body: any = {}, withResponse: boolean = true, options?: RequestInit): Promise<T> => {
    console.debug('[Fetch]: POST', url, body);
    const payload = body;
    return this.httpService
      .fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: EntityApiServiceFetchImpl.getDefaultHeaders(),
        ...options,
      })
      .then((data: Response) => {
        if (withResponse) return this.parseResponse(data).then(this.parseResponsePayload);
        else return this.parseResponse(data);
      });
  };

  /** @throws `BackendResponseError` */
  public postWithCredentials = <T>(url: string, body: any = {}, withResponse: boolean = true): Promise<T> => {
    return this.post(url, body, withResponse, { credentials: 'include' });
  };

  /** @throws `BackendResponseError` */
  public postMultipart = <T>(url: string, body: FormData): Promise<T> => {
    console.debug('[Fetch]: POST', url, body);
    return this.httpService
      .fetch(url, {
        method: 'POST',
        body,
        credentials: 'include',
        headers: { [CSRF_HEADER_NAME]: EntityApiServiceFetchImpl.getCSRFToken() },
      })
      .then(this.parseResponse)
      .then(this.parseResponsePayload);
  };

  /** @throws `BackendResponseError` */
  public put = (url: string, body: any = {}, options?: RequestInit): Promise<void> => {
    console.debug('[Fetch]: PUT', url, body);
    return this.httpService
      .fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: EntityApiServiceFetchImpl.getDefaultHeaders(),
        ...options,
      })
      .then(this.parseResponse);
  };

  /** @throws `BackendResponseError` */
  public putWithCredentials = (url: string, body: any = {}): Promise<void> => {
    return this.put(url, body, { credentials: 'include' });
  };

  /** @throws `BackendResponseError` */
  public putWithResponse = <T>(url: string, body: any = {}): Promise<T> => {
    console.debug('[Fetch]: PUT', url, body);
    const payload = body;
    return this.httpService
      .fetch(url, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: EntityApiServiceFetchImpl.getDefaultHeaders(),
        credentials: 'include',
      })
      .then((data: Response) => {
        return this.parseResponse(data);
      });
  };

  /** @throws `BackendResponseError` */
  public patch = <T>(url: string, body: any = {}, withResponse?: boolean, options?: RequestInit): Promise<T> => {
    console.debug('[Fetch]: PATCH', url, body);
    return this.httpService
      .fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: EntityApiServiceFetchImpl.getDefaultHeaders(),
        ...options,
      })
      .then((data: Response) => {
        if (withResponse) return this.parseResponse(data).then(this.parseResponsePayload);
        else return this.parseResponse(data);
      });
  };

  /** @throws `BackendResponseError` */
  public patchWithCredentials = <T>(url: string, body: any = {}, withResponse?: boolean): Promise<T> => {
    return this.patch(url, body, withResponse, { credentials: 'include' });
  };

  /** @throws `BackendResponseError` */
  public delete = <T = void>(url: string, options?: RequestInit): Promise<T> => {
    console.debug('[Fetch]: DELETE', url);
    return this.httpService
      .fetch(url, {
        method: 'DELETE',
        headers: EntityApiServiceFetchImpl.getDefaultHeaders(),
        ...options,
      })
      .then(this.parseResponse);
  };

  /** @throws `BackendResponseError` */
  public deleteWithCredentials = (url: string): Promise<void> => {
    return this.delete(url, { credentials: 'include' });
  };

  /** @throws `BackendResponseError` */
  private parseResponse = async (res: Response) => {
    if (res.status === 204) {
      return;
    }
    let details;
    try {
      const contentType = res.headers.get('Content-Type');
      // application/json не используется, т.к. есть кастомные типы, см getListWithIdAndNamesOnly
      if (contentType?.includes('json')) {
        const jsonResponse = await res.json();
        if (res.ok) return jsonResponse;
        else {
          details = jsonResponse.error.details;
          throw new Error(jsonResponse.error?.message || jsonResponse.message);
        } // jsonResponse.error это поле, в котором бэкенд отдаёт ошибки
      } else if (!res.ok) {
        throw new Error();
      }
    } catch (error: Error | any) {
      console.error('[Fetch] Error: ' + error?.message);
      throw this.getError(res.status, error?.message, details);
    }
  };

  private parseResponsePayloadWithPaging = ({ data, paging }: ResponseContainerDTO): EntityListData<any> => ({
    data: data,
    pagination: paging,
  });
  private parseResponsePayload = (response: ResponseContainerDTO) => response?.data;

  private getError = (code: number, message?: string, details?: Record<string, string>): FetchError | UnauthorizedError => {
    switch (code) {
      case 404:
        if (!message) message = this.languageService.translate(`errors.code.${code}`);
        break;
      case 403:
        if (!message) message = this.languageService.translate(`errors.code.${code}`);
        return new ForbiddenError(message);
      case 401:
        if (!message) message = this.languageService.translate(`errors.code.${code}`);
        return new UnauthorizedError(message);
      default:
        if (!message) message = 'Request failed';
    }
    return new FetchError(message, code, undefined, details);
  };
}
