import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import {
  OrderContractSessionAddressDataRequestDTO,
  OrderContractSessionClientDataRequestDTO,
  OrderContractSessionDataRequestDTO,
  OrderContractSessionDataResponseDTO,
  OrderNumberGenerateRequestDTO,
} from 'typings/dto/order';

import IOrderSessionActionsService from 'services/storageModelActions/orderSession/IOrderSessionActionsService';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import { batch as reduxBatch } from 'react-redux';
import slices from 'storage/slices';
import NavigateBackendUtils from 'utils/NavigateBackend';

export default class IOrderSessionActionsServiceImpl
  extends ModelActionsService<OrderContractSessionData, OrderContractSessionDataRequestDTO, OrderContractSessionDataResponseDTO>
  implements IOrderSessionActionsService
{

  public async generateOrderNumber(dto: OrderNumberGenerateRequestDTO) {
    let url = PATH_BACKEND.orderSession.root + '/' + PATH_BACKEND_PART.orderSession.generateOrderNumber;
    url = NavigateBackendUtils.addParamsToExistedUrl(url, dto)
    return this.entityApiService.getWithCredentials<string>(url);
  }

  public async createSession(dto: OrderContractSessionDataRequestDTO): Promise<string> {
    const url = PATH_BACKEND.orderSession.root;
    const res = await this.entityApiService.postWithCredentials<{ id: string }>(url, dto);
    return res.id;
  }

  public async cancelSession(sessionId: string): Promise<void> {
    const url = PATH_BACKEND.orderSession.root + `/${sessionId}/` + PATH_BACKEND_PART.orderSession.cancelSession;
    await this.entityApiService.postWithCredentials<void>(url, {}, false);
  }
  public async createOrderFromSession(sessionId: string): Promise<string> {
    const url = PATH_BACKEND.orderSession.root + `/${sessionId}/` + PATH_BACKEND_PART.orderSession.createOrder;
    const res = await this.entityApiService.postWithCredentials<{ id: string }>(url);
    return res.id;
  }

  public async updateAddressData(sessionId: string, dto: OrderContractSessionAddressDataRequestDTO): Promise<void> {
    const url = PATH_BACKEND.orderSession.root + `/${sessionId}/` + PATH_BACKEND_PART.orderSession.address;
    return this.entityApiService.patchWithCredentials(url, dto);
  }

  public async updateClientData(sessionId: string, dto: OrderContractSessionClientDataRequestDTO): Promise<void> {
    const url = PATH_BACKEND.orderSession.root + `/${sessionId}/` + PATH_BACKEND_PART.orderSession.customer;
    return this.entityApiService.patchWithCredentials(url, dto);
  }

  public async getOrderSession(sessionId: string): Promise<void> {
    this.storageDispatch(this.modelStorageActions.startLoading());
    this.getOrderSessionBackground(sessionId);
  }

  public async getOrderSessionBackground(sessionId: string): Promise<void> {
    const url = PATH_BACKEND.orderSession.root + `/${sessionId}`;
    await reduxBatch(async () => {
      try {
        const response = await this.entityApiService.getWithCredentials<OrderContractSessionDataResponseDTO>(url).then((session) => {
          return this.modelMapper.responseDTOToModel(session);
        });
        if (!this.areActionsOutdated) this.setOne(response);
        else console.warn(`Get one request ${this.modelStorageName} is outdated`);
      } catch (error) {
        if (!this.areActionsOutdated) {
          console.error(error);
          this.storageDispatch(this.modelStorageActions.setError(error));
        }
      }
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.stopLoading());
    });
  }

  public setOne = (orderSession: OrderContractSessionData | null) => {
    this.storageDispatch(this.modelStorageActions.setOne(orderSession));
    if (orderSession) {
      this.storageDispatch(slices.serviceOrdered.actions.setList({ data: orderSession.services }));
    } else {
      this.storageDispatch(slices.serviceOrdered.actions.setList({ data: [] }));
    }
  };
}
