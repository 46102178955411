import { Collapse, TableCell, TableRow } from '@mui/material';
import React, { Children, ReactNode, useMemo, useState } from 'react';
import SpoilerIconButton from 'components/ui/buttons/SpoilerIconButton';
import { OrderServicePackageIcon } from 'components/ui/icons';
import EmptyIconButton from 'components/ui/buttons/EmptyIconButton';
import ServiceOrderedPackageClientPriceBlock from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedPackageClientPriceBlock';
import { Table } from '@mui/material';
import { Tooltip } from '@mui/material';
import useLocales from 'hooks/useLocales';

type Props = {
  packageServices: ServiceOrdered[];
  children: ReactNode
};

function ServiceOrderedPackageListInfoItem({
  packageServices,
  children
}: Props) {
  const { translate } = useLocales()
  const [isDetailsOpened, setIsDetailsOpened] = useState(false);

  const servicePackage = packageServices[0].package

  const { isHasAnyDiscount, totalDiscount, totalClientValue, currency } = useMemo(() => {
    const initialValue = {
      isHasAnyDiscount: false,
      totalClientValue: 0,
      totalDiscount: 0,
      isPackageFullPaid: true,
      isPackageNotPaid: false,
      currency: packageServices[0].price.currency
    }

    const servicePackagePaidInformation = packageServices.reduce((acc, service) => {
      acc.isHasAnyDiscount = acc.isHasAnyDiscount || Boolean(service.promotion?.discounts.length) || service.price.discount.value > 0
      acc.totalClientValue = acc.totalClientValue + service.price.totalClientValue
      acc.totalDiscount = acc.totalDiscount + service.price.initialValue * service.quantity -
        service.price.totalClientValue +
        ((service.promotion?.priceBefore || 0) - (service.promotion?.priceAfter || 0)) * service.quantity
      return acc
    }, initialValue)

    return servicePackagePaidInformation
  }, [packageServices])

  if (!packageServices.length || !servicePackage) return null


  return (
    <>
      <TableRow style={{ background: '#F4F6F8', borderTop: '0' }} >
        <TableCell>{servicePackage.name}</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
          <Tooltip title={translate('entities.servicePackage.entityName')}>
            <OrderServicePackageIcon />
          </Tooltip>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ServiceOrderedPackageClientPriceBlock
            currency={currency}
            isHasAnyDiscount={isHasAnyDiscount}
            totalClientValue={totalClientValue}
            totalDiscount={totalDiscount}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
          <EmptyIconButton />
          <SpoilerIconButton isOpened={isDetailsOpened} onClick={() => setIsDetailsOpened(!isDetailsOpened)} />
        </TableCell>
      </TableRow>

      <TableRow sx={{ background: '#F9F9F9' }} style={{ borderBlock: '0px' }}>
        <TableCell sx={{ p: 0, borderBlock: '0px' }} colSpan={4}>
          <Collapse in={isDetailsOpened}>
            <Table>
              <colgroup>
                <col width={'60%'} />
                <col width={'20%'} />
                <col width={'19%'} />
                <col width={'1%'} />
              </colgroup>
              {Children.map(children, (child) => (
                <TableRow>
                  {child}
                </TableRow>
              ))}
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>

    </>
  );
}

export default React.memo(
  ServiceOrderedPackageListInfoItem,
  (pp, np) => pp.packageServices === np.packageServices
);
