import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import { AllNotificationSettings, DeactivateUserRequestDTO } from 'typings/dto/user';
import {
  CreateUserEnterpriseRequestDTO,
  GetRetailerUserEnterpriseListRequestDTO,
  ResetEnterpriseUserApiKeyRequestDTO,
  UserEnterpriseResponseDTO,
} from 'typings/dto/userEnterprise';
import { TechnicianAvailabilityRequestDTO, TechnicianAvailabilityResponseDTO } from 'typings/dto/userSP';
import { USER_POSITION, USER_ROLE } from 'typings/models/user.enum';
import UserUtils from 'utils/models/UserUtils';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import ModelActionsService from '../ModelActionsService';
import IUserEnterpriseActionsService from './IUserEnterpriseActionsService';

export default class UserEnterpriseActionsService
  extends ModelActionsService<UserEnterprise, CreateUserEnterpriseRequestDTO, UserEnterpriseResponseDTO>
  implements IUserEnterpriseActionsService
{
  /** @throws `BackendResponseError` */
  resetNotificationSettingsByPositionId = ({ userPositionId }: { userPositionId: string }) => {
    return this.entityApiService.deleteWithCredentials(PATH_BACKEND.notifications.notifications + '/' + userPositionId);
  };

  /** @throws `BackendResponseError` */
  resetClientNotificationSettings = () => {
    return this.entityApiService.deleteWithCredentials(PATH_BACKEND.notifications.notifications + '/client');
  };

  /** @throws `BackendResponseError` */
  public updateNotificationSettings = ({
    userPositionId,
    ...dto
  }: { userPositionId: string } & AllNotificationSettings) => {
    return this.entityApiService.putWithCredentials(
      PATH_BACKEND.notifications.notifications + '?userPositionId=' + userPositionId,
      dto
    );
  };

  /** @throws `BackendResponseError` */
  public updateClientNotificationSettings = ({ ...dto }: AllNotificationSettings) => {
    return this.entityApiService.putWithCredentials(PATH_BACKEND.notifications.notifications + '/client', dto);
  };

  /** @throws `BackendResponseError` */
  public getApiUserList = (dto: GetRetailerUserEnterpriseListRequestDTO): Promise<EntityWithTenant[]> => {
    const url =
      PATH_BACKEND.enterprise.root +
      '/' +
      dto.tenantId +
      '/' +
      PATH_BACKEND_PART.enterprise.settings +
      '/' +
      PATH_BACKEND_PART.enterprise.api +
      '/users';
    return this.entityApiService.getWithCredentials(url);
  };

  /** @throws `BackendResponseError` */
  public resetApiUserKey(dto: ResetEnterpriseUserApiKeyRequestDTO): Promise<string> {
    const url =
      PATH_BACKEND.enterprise.root +
      '/' +
      dto.tenantId +
      '/' +
      PATH_BACKEND_PART.enterprise.settings +
      '/' +
      PATH_BACKEND_PART.enterprise.api +
      '/' +
      'users' +
      '/' +
      dto.userId +
      '/generate';
    return this.entityApiService.postWithCredentials<string>(url);
  }

  /** @throws `BackendResponseError` */
  public deactivateUser = (dto: DeactivateUserRequestDTO): Promise<void> => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.user.disable, dto, false);
  };

  /** @throws `BackendResponseError` */
  public getAllForFilter = (dto: GetRetailerUserEnterpriseListRequestDTO) => {
    let filter: Partial<Record<keyof User, LocationSearchParam>> = {
      position: NavigateFrontendUtils.createLocationSearchParam(USER_POSITION.technician, 'ne'),
    };
    filter = this.getDefaultSortFilter(filter);
    return super.getAllForFilter(dto, filter, PATH_BACKEND.user.idNameOnly);
  };

  /** @throws `BackendResponseError` */
  public getAllManagersForFilter = (dto: GetRetailerUserEnterpriseListRequestDTO) => {
    let filter = UserUtils.getUserRoleLocationSearchObject(USER_ROLE.orderManager);
    filter = this.getDefaultSortFilter(filter);
    return super.getAllForFilter(dto, filter, PATH_BACKEND.user.idNameOnly);
  };

  /** @throws `BackendResponseError` */
  public getAllTechniciansForFilter = (dto: GetRetailerUserEnterpriseListRequestDTO, aditionalFilter: LocationSearchObject): Promise<void> => {
    let filter = UserUtils.getUserRoleLocationSearchObject(USER_ROLE.orderTechnician);
    filter = this.getDefaultSortFilter(filter);
    return super.getAllForFilter(dto, { ...filter, ...aditionalFilter }, PATH_BACKEND.user.idNameOnly);
  };

  /** @throws `BackendResponseError` */
  public checkTechnicianAvailability = ({
    orderId,
    ...dto
  }: TechnicianAvailabilityRequestDTO): Promise<TechnicianAvailabilityResponseDTO[]> => {
    const url = `${PATH_BACKEND.user.root}/order/${orderId}/${PATH_BACKEND_PART.userSP.availability}`;
    return this.entityApiService.postWithCredentials<TechnicianAvailabilityResponseDTO[]>(url, dto, true);
  };

  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'name');
  };
}
