export enum ORDER_STATUS_NAME {
  inProgress = 'IN_PROGRESS',
  providerNotSet = 'IN_PROGRESS_PROVIDER_NOT_SET',
  providerSet = 'IN_PROGRESS_PROVIDER_IS_SET',
  inProgressProviderSet = 'IN_PROGRESS_PROVIDER_IS_SET_WORK_IN_PROGRESS',
  workFinished = 'WORK_FINISHED',
  completed = 'COMPLETED',
  paused = 'PAUSED',
  cancelled = 'CANCELLED',
  calculation = 'CALCULATION',
  contractNotSigned = 'CONTRACT_NOT_SIGNED',
  contractSigned = 'CONTRACT_SIGNED',
}

// Костыли со статусом заказа
// export enum FAKE_ORDER_STATUS_NAME {
//   IN_PROGRESS_PROVIDER_NOT_SET = 'IN_PROGRESS_PROVIDER_NOT_SET',
//   IN_PROGRESS_PROVIDER_IS_SET = 'IN_PROGRESS_PROVIDER_IS_SET',
//   IN_PROGRESS_PROVIDER_IS_SET_WORK_IN_PROGRESS = 'IN_PROGRESS_PROVIDER_IS_SET_WORK_IN_PROGRESS',
// }

export enum ORDER_VISIT_DATE_COMPLETION_STATE {
  good = 'ON_TIME',
  bad = 'IS_MISSED',
  warn = 'IS_LATE',
}

export enum ORDER_COMPLETION_STATE {
  good = 'COMPLETED_ON_TIME',
  warn = 'COMPLETED_NOT_ON_TIME',
  bad = 'OVERDUE',
}
export const ORDER_COMPLETION_STATE_NOT_OVERDUE = 'NOT_OVERDUE';

export enum ORDER_CUSTOM_FIELD_TYPE {
  file = 'FILE',
  date = 'DATE',
  number = 'NUMBER',
  text = 'TEXT',
  employee = 'EMPLOYEE',
  boolean_radiogroup = 'BOOLEAN_RADIOGROUP',
  select_options = 'SELECT_OPTIONS',
}

export enum ORDER_CUSTOM_FIELD_CATEGORY {
  product = 'PRODUCT',
  client = 'CLIENT',
  otherInfo = 'OTHER_INFO',
}

export enum ORDER_CLIENT_ROOM_TYPE {
  apartment = 'APARTMENT',
  house = 'HOUSE',
  office = 'OFFICE',
  b2b = 'B2B'
}

// SP

export enum ORDER_OPERATOR_TYPE {
  none = 'NONE',
  platformOperator = 'PLATFORM_OPERATOR',
}

export enum ORDER_SERVICE_PROVIDER_TYPE {
  serviceProvider = 'SERVICE_PROVIDER',
  internalTechnician = 'INTERNAL_TECHNICIAN',
  none = 'NONE',
}

export enum ORDER_CUSTOMER_TYPE {
  enterprise = 'ENTERPRISE',
  platformOperator = 'PLATFORM_OPERATOR',
}

export enum ORDER_SERVICE_PROVIDER_ASSIGN_TYPE {
  none = 'NONE',
  auto = 'AUTO',
  // autoInProgress = 'AUTO_IN_PROGRESS',
  manual = 'MANUAL',
}

export enum ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM {
  none = 'NONE',
  internalTechnicianManual = 'INTERNAL_TECHNICIAN_MANUAL',
  internalTechnicianConcurrent = 'CONCURRENT_OFFER_INTERNAL_TECHNICIAN',
  internalTeamManual = 'INTERNAL_TEAM_MANUAL',
  internalTeamConcurrent = 'CONCURRENT_OFFER_INTERNAL_TEAM',
  spManual = 'SERVICE_PROVIDER_MANUAL',
  spConcurrent = 'CONCURRENT_OFFER_SP',
  spTechnicianManual = 'SERVICE_PROVIDER_TECHNICIAN_MANUAL',
  poManual = 'PLATFORM_OPERATOR_MANUAL',
}

export enum ORDER_TECHNICIANS_ASSIGN_STATUS {
  none = 'NONE',
  full = 'FULL',
  partial = 'PARTIAL',
}

export enum ORDER_SESSION_TYPE {
  calculation = 'CALCULATION',
  contract = 'CONTRACT',
}

export enum RECOMMENDATION_STATUS {
  required = 'REQUIRED',
  recommendedDefaultOn = 'RECOMMENDED_DEFAULT_ON',
  recommendedDefaultOff = 'RECOMMENDED_DEFAULT_OFF',
}

export enum ORDER_DEFAULT_DURATION_BY_TYPE {
  default = 8,
  reclamation = 4,
  warranty = 4,
  measure = 2
}