import React from 'react';
import { Stack, Typography } from '@mui/material';
import useDI from 'hooks/useDI';
import Label from 'components/ui/info/Label';
import ILanguageService from 'services/language/ILanguageService';

type Props = {
  isHasAnyDiscount: boolean
  totalDiscount: number
  totalClientValue: number
  isMobile?: boolean
  currency: ILanguageService.CurrencyCode
}

function ServiceOrderedClientPriceBlock({ isHasAnyDiscount, totalClientValue, totalDiscount, currency, isMobile }: Props) {
  const { statefulUtils, services } = useDI();
  const { translate } = services.language;

  if (isMobile) {
    return (
      <Stack spacing={0.5}>
        <Stack direction='row' spacing={1}>
          <Stack>
            <Typography variant="caption">{statefulUtils.money.toString(totalClientValue, currency)} </Typography>
          </Stack>
          {isHasAnyDiscount && (
            <Typography variant='caption' color="text.secondary" sx={{ textDecoration: 'line-through' }}>
              {statefulUtils.money.toString(totalClientValue + totalDiscount, currency)}
            </Typography>
          )}
        </Stack>
        {isHasAnyDiscount && (
          <Label variant="ghost" sx={{ alignSelf: 'flex-start' }}>
            {translate('entities.service.discount')} {statefulUtils.money.toString(totalDiscount, currency)}
          </Label>
        )}
      </Stack>
    )
  }

  return (
    <Stack>
      <Typography variant="inherit">{statefulUtils.money.toString(totalClientValue, currency)} </Typography>
      {isHasAnyDiscount && (
        <>
          <Typography color="text.secondary" sx={{ textDecoration: 'line-through', mt: 1 }}>
            {statefulUtils.money.toString(totalClientValue + totalDiscount, currency)}
          </Typography>
          <Label variant="ghost" sx={{ alignSelf: 'flex-start' }}>
            {translate('entities.service.discount')} {statefulUtils.money.toString(totalDiscount, currency)}
          </Label>
        </>
      )}
    </Stack>
  );
}

export default React.memo(ServiceOrderedClientPriceBlock, (pp, np) => (
  pp.isHasAnyDiscount === np.isHasAnyDiscount &&
  pp.currency === np.currency &&
  pp.isMobile === np.isMobile &&
  pp.totalClientValue === np.totalClientValue &&
  pp.totalDiscount === np.totalDiscount
));
