import { Box, BoxProps, Table, TableHead, TableBody, Stack, TableRow } from '@mui/material';
import React from 'react';
import PaginationButtons from '../../features/pagination/PaginationButtons';
import LoaderComponent from '../loadingIndicators/LoaderComponent';

type Props = BoxProps & {
  /** Ждёт TableRow (можно и списком, но обычно одна) */
  header: React.ReactNode | React.ReactNode[];
  /** Ждёт список TableRow */
  children: React.ReactNode[];
  pagination?: Pagination;
  isLoading?: boolean;
  emptyBlock?: JSX.Element;
  /** Параметр поиска, который всегда будет применяться */
  initialFilter?: LocationSearchObject;
};

export default function TableVariant2({ header, initialFilter, children, emptyBlock, pagination, isLoading, sx, ...rest }: Props) {
  return (
    <Box sx={{ mt: 3, ...sx }} {...rest}>
      <Box sx={{ overflowX: 'auto' }}>
        <Table
          sx={{
            '& tr': {
              borderBlock: '1px solid #919EAB3D',
              background: '#F9F9F9',
              p: 0
            },
            '& tr:first-of-type': {
              borderTop: { md: 'none', }
            },
            '& thead th:first-of-type': {
              borderTopLeftRadius: '8px'
            },
            '& thead th:last-of-type': {
              borderTopRightRadius: '8px'
            },
          }}
          size="medium">
          <TableHead
            sx={{ '& tr': { background: '#F4F6F8', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' } }}
          >
            {header}
          </TableHead>
          <TableBody>{isLoading ? <TableRow /> : children}</TableBody>
        </Table>
      </Box>
      {isLoading ? <LoaderComponent /> : !children.length && emptyBlock ? emptyBlock : null}
      {pagination && pagination.totalPages > 1 && (
        <Stack direction="row" justifyContent={{ xs: 'flex-start', md: 'space-between' }} alignItems="center" spacing={2} sx={{ mt: 2 }}>
          <PaginationButtons count={pagination.totalPages} page={pagination.pageNumber} boundaryCount={1} initialFilter={initialFilter} />
          {/* <SizeSelector size={10} /> */}
        </Stack>
      )}
    </Box>
  );
}

