import { useMemo, useRef } from 'react';
import { FormUtils } from 'utils/Form';

export type ChildFormsState = {
  /**
   * Ссылка на карту форм. Сделано в виде ref для memo компонентов
   * @see FormUtils.ChildFormsMap
   */
  formsArrayRef: React.MutableRefObject<FormUtils.ChildFormArray>;
  /** сабмит всех форм-потомков, возвращаем промис с массивом ответов */
  submitForms: <T = any>() => Promise<T[]>;
};

/**
 * Создаёт стейт, обслуживающий формы-потомки одной сущности; начальные значения можно передать в сам компонент формы
 * @param initialItemsCount начальное количество форм
 * Отличие от useChildForms заключается в том, что хранит все формы не в мапе, а в списке
 */
export default function useChildFormArray(initialItemsCount: number = 0): ChildFormsState {
  const initialItems = useMemo(() => {
    // null т.к. туда будет записан id формы в момент инициализации компонента формы, но чтобы стригерить его инициализацию, нужно задать id
    const initialItems: null[] = [];
    for (let i = 0; i < initialItemsCount; i++) {
      initialItems.push(null)
    }
    return initialItems;
  }, []);

  const formsArrayRef = useRef<FormUtils.ChildFormArray>(initialItems);
  const submitForms = async <T = any>() => {
    const forms = formsArrayRef.current;
    return (await Promise.all(forms.map((f) => f?.submitForm()))) as unknown as T[];
  };

  return {
    formsArrayRef,
    submitForms,
  };
}
