import { Box, Button, Stack, Typography } from '@mui/material';
import { ItemRecommendationDTO } from 'typings/dto/recommendationsConfig';
import React, { useMemo } from 'react';
import useLocales from 'hooks/useLocales';
import ServiceOrderedClientPriceBlock from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedClientPriceBlock';
import ServiceOrderedUtils from 'utils/models/ServiceOrderedUtils';
import AmountInputMemorized from 'components/ui/forms/AmountInputMemorized';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { CreateServiceOrderedRequestDTO } from 'typings/dto/serviceOrdered';
import StringUtils from 'utils/String';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import FormProvider from 'components/ui/forms/FormProvider';
import IconButtonStyled from 'components/ui/buttons/IconButtonStyled';
import { CartIcon } from 'components/ui/icons';
import FormUtils from 'utils/Form';
import { PRICE_VALUE_TYPE } from 'typings/models/price.enum';

type Props = {
  recommendations: ItemRecommendationDTO[];
  servicesFromCatalog: Price[];
  orderSessionId: string;
  submitHandler: (dto: CreateServiceOrderedRequestDTO, formId: string) => Promise<void>;
  formsStatesMap: FormUtils.ChildFormsMap;
  formsKeys: string[];
  submitAllRecommendationForms: VoidFunction;
};
export default function OrderRecommendationsListBlock({
  recommendations,
  servicesFromCatalog,
  orderSessionId,
  submitHandler,
  formsStatesMap,
  formsKeys,
  submitAllRecommendationForms,
}: Props) {
  const { translate } = useLocales();

  const recommendationServices = formsKeys.map((formId, index) => servicesFromCatalog.find((s) => s.service.technicalName === recommendations[index].itemKey))
  if (!recommendationServices.some(s => Boolean(s))) {
    return null
  }

  return (
    <Stack flex={1} spacing={1} direction="column" sx={{ backgroundColor: (theme) => theme.palette.grey[200], p: 2, borderRadius: 2 }}>
      <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="baseline">
        <Typography variant="h6">{translate('pages.orderAdd.recommendationList')}</Typography>
        <Button variant={'outlined'} onClick={submitAllRecommendationForms}>
          {translate('pages.orderAdd.addAllRecommendations')}
        </Button>
      </Stack>
      <Box>
        {formsKeys.map((formId, index) => {
          const service = recommendationServices[index];
          if (!recommendations[index]) return null;
          if (!service) return null;
          return (
            <RecommendationListItem
              price={service}
              item={recommendations[index]}
              key={recommendations[index].itemKey}
              orderSessionId={orderSessionId}
              submitHandler={submitHandler}
              formId={formId}
              formsStatesMap={formsStatesMap}
            />
          );
        })}
      </Box>
    </Stack>
  );
}

function RecommendationListItem({
  price,
  item,
  orderSessionId,
  submitHandler,
  formId,
  formsStatesMap,
}: {
  price: Price;
  item: ItemRecommendationDTO;
  orderSessionId: string;
  submitHandler: (dto: CreateServiceOrderedRequestDTO, formId: string) => Promise<void>;
  formId: string;
  formsStatesMap: FormUtils.ChildFormsMap;
}) {
  const { translate } = useLocales();
  const handleFormErrors = useSnackbarErrorHandler();
  const [validationSchema, initialValues] = useMemo(() => {
    const fieldIsRequiredText = translate('errors.fieldIsRequired');
    const valueIsTooLowText = translate('errors.valueIsTooSmall');
    const validationSchema = Yup.object().shape({
      quantity: Yup.number().min(1, valueIsTooLowText).required(fieldIsRequiredText),
    });

    const initialValues = {
      quantity: item.quantity,
    };

    return [validationSchema, initialValues];
  }, [item?.itemKey, price.id]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }): Promise<CreateServiceOrderedRequestDTO | undefined> => {
      let priceForProductPricePercent = 0;
      if (price.clientValue && price.clientValue.value && price.clientValue.type === PRICE_VALUE_TYPE.percent && price.productPrice) {
        priceForProductPricePercent = price.clientValue.value * price.productPrice * 0.01 * values.quantity;
      }
      try {
        setSubmitting(true);
        const dto: CreateServiceOrderedRequestDTO = {
          name: price.name,
          custom: false,
          paid: false,
          quantity: values.quantity,
          includedText: price.service.included,
          notIncludedText: price.service.notIncluded,
          technicalName: price ? price.service.technicalName : StringUtils.generateUUIDv4(),
          price: {
            currency: price.currency,
            discount: { type: 'PERCENT', value: 0 },
            value:
              price.clientValue && price.clientValue.type !== PRICE_VALUE_TYPE.percent ? price.clientPrice : priceForProductPricePercent,
            catalogPrice: price.clientValue,
          },
          productPrice: price.clientValue?.type !== PRICE_VALUE_TYPE.percent ? null : price.productPrice || null,
          recommendationStatus: item.status,
          catalogId: price.service ? price.service.id : undefined,
          orderId: orderSessionId,
          workEstimation: price.service.workEstimation,
          linkedEntities: [],
          promotion: price ? price.promotion : undefined,
        };
        return dto;
      } catch (error) {
        handleFormErrors({ callback: () => setSubmitting(false), error });
      }
    },
  });

  formsStatesMap.set(formId, formState);

  return (
    <FormProvider formState={formState} fullWidth>
      <Stack
        direction="row"
        key={item.itemKey}
        sx={{ backgroundColor: (theme) => theme.palette.background.default, mt: 2, p: 2, borderRadius: 2 }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography variant={'body2'} sx={{ width: '50%' }}>
          {price.name}
        </Typography>

        <Box width={'30%'}>
          <AmountInputMemorized label={translate('fields.quantity')} fieldName="quantity" formState={formState} fullWidth />
        </Box>
        <Box width={'15%'}>
          <ServiceOrderedClientPriceBlock service={ServiceOrderedUtils.priceToServiceOrdered(price)} />
        </Box>
        <Typography width={'5%'} variant={'body2'}>
          <IconButtonStyled
            title={translate('pages.orderAdd.addService')}
            onClick={async () => {
              const dto = await formState.submitForm();
              await submitHandler(dto, formId);
            }}
          >
            <CartIcon />
          </IconButtonStyled>
        </Typography>
      </Stack>
    </FormProvider>
  );
}
