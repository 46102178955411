import { Pagination, PaginationProps } from '@mui/material';
import useStableNavigate from 'hooks/useStableNavigate';
import React from 'react';
import NavigateFrontendUtils from 'utils/NavigateFrontend';

type Props = PaginationProps & {
  page: number;
  count: number;
  /** Параметр поиска, который всегда будет применяться */
  initialFilter?: LocationSearchObject;
};

export default function PaginationButtons({ initialFilter, page: lastLoadedPage, count: totalPages, ...rest }: Props) {
  const navigate = useStableNavigate();

  const changeHandler = (_event: React.ChangeEvent<unknown>, value: number) => {
    let params = NavigateFrontendUtils.getLocationSearchObjectFromCurrentLocation();
    if (params.page?.rawValue === value.toString()) {
      params[NavigateFrontendUtils.FORCE_RELOAD] = NavigateFrontendUtils.createLocationSearchParam(true);
    } else {
      delete params[NavigateFrontendUtils.FORCE_RELOAD];
    }
    params.page = NavigateFrontendUtils.createLocationSearchParam(value);
    if (initialFilter) {
      params = { ...initialFilter, ...params };
    }
    navigate(NavigateFrontendUtils.setSearchQueryToCurrentPathname(params));
  };

  return <Pagination count={totalPages} page={lastLoadedPage} onChange={changeHandler} {...rest} />;
}
