import { Stack, Typography } from '@mui/material';
import CheckboxMemorized from 'components/ui/forms/CheckboxMemorized';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import UploadFilesMemorized from 'components/ui/forms/files/UploadFilesMemorized';
import UploadedFilesMemorized from 'components/ui/forms/files/UploadedFilesMemorized';
import { FormikContextType } from 'formik';
import { useMemo } from 'react';
import { DYNAMIC_FIELD_TYPE } from 'typings/subEntities/dynamicField.enum';
import FileUtils from 'utils/File';
import FunctionUtils from 'utils/Function';
import DynamicFieldRadioGroupEditState from './DynamicFieldRadioGroupEditState';
import DynamicFieldSelectEditState from './DynamicFieldSelectEditState';

type Props = {
  field: DynamicField;
  formState: FormikContextType<any>;
  /** Если для селекта требуется передать варианты из вне */
  dataSourceOptions?: any[];
  areDataSourceOptionsLoading?: boolean;
  isAddForm?: boolean;
  keyProp?: string;
  allowedFormats?: ReturnType<typeof FileUtils.getFormat>[];
  isRenameFile?: boolean;
};

export default function DynamicFieldEditState({
  field,
  formState,
  areDataSourceOptionsLoading,
  dataSourceOptions,
  isAddForm,
  keyProp,
  allowedFormats,
  isRenameFile,
}: Props) {
  // Сейчас больше не надо грузить старые файлы в аплоадер, т.к. они отображаются в отдельном компоненте без загрузки
  // useEffect(() => {
  //   if (field.type !== DYNAMIC_FIELD_TYPE.file) {
  //     return;
  //   }

  //   const initializeFiles = async () => {
  //     const filesRequests = field.values.map((f) => apiService.subEntity.file.getFileForEditor(f));
  //     const filesForUploader = await Promise.all(filesRequests);
  //     formState.setFieldValue(field.technicalName, filesForUploader);
  //   };

  //   initializeFiles()
  //     .catch((error) => handleFormErrors({ error }))
  //     .finally(() => setAreFilesInitialized(true));
  // }, []);

  const [leftText, rightText] = useMemo(() => {
    // Маленькая договорённость с беком что у данного типа по данному символу можно разбить текст пополам, при этом без разбивки смысл не теряется
    if (field.type === DYNAMIC_FIELD_TYPE.switch) {
      return field.name.split('\\');
    } else return ['', ''];
  }, []);

  switch (field.type) {
    case DYNAMIC_FIELD_TYPE.file: {
      return (
        <Stack direction="column" spacing={1}>
          {isAddForm && <Typography variant="subtitle2">{field.name}</Typography>}
          <UploadedFilesMemorized fieldName={field.technicalName + '_old'} formState={formState} />
          <UploadFilesMemorized
            label={field.name}
            multiple={field.multiple}
            maxFiles={field.multiple ? undefined : 1} // TODO если флаг multiple работает нормально, то это не нужно
            fieldName={field.technicalName}
            formState={formState}
            // isLoading={!areFilesInitialized}
            // disabled={!areFilesInitialized}
            allowedFormats={allowedFormats}
            isRenameFile={isRenameFile}
          />
        </Stack>
      );
    }
    case DYNAMIC_FIELD_TYPE.number: {
      return <TextFieldMemorized label={field.name} fieldName={field.technicalName} formState={formState} type="number" />;
    }
    case DYNAMIC_FIELD_TYPE.text: {
      return <TextFieldMemorized label={field.name} fieldName={field.technicalName} formState={formState} />;
    }
    case DYNAMIC_FIELD_TYPE.textarea: {
      return <TextFieldMemorized label={field.name} fieldName={field.technicalName} formState={formState} multiline minRows={5} />;
    }
    case DYNAMIC_FIELD_TYPE.switch: {
      return (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ overflow: 'auto' }}>
          <Typography variant="body2">{leftText}</Typography>
          <CheckboxMemorized variant="switch" fieldName={field.technicalName} formState={formState} />
          <Typography variant="body2">{rightText}</Typography>
        </Stack>
      );
    }
    case DYNAMIC_FIELD_TYPE.checkbox: {
      return <CheckboxMemorized fieldName={field.technicalName} label={field.name} formState={formState} />;
    }
    case DYNAMIC_FIELD_TYPE.select: {
      return (
        <DynamicFieldSelectEditState
          field={field}
          formState={formState}
          dataSourceOptions={dataSourceOptions}
          areDataSourceOptionsLoading={areDataSourceOptionsLoading}
          keyProp={keyProp}
        />
      );
    }
    case DYNAMIC_FIELD_TYPE.radioGroup: {
      return <DynamicFieldRadioGroupEditState field={field} formState={formState} showLabel={isAddForm} key={keyProp} />;
    }
    case DYNAMIC_FIELD_TYPE.date:
    case DYNAMIC_FIELD_TYPE.visitDate: {
      // Спец поля
      return null;
    }
    default: {
      FunctionUtils.exhaustiveCheck(field);
      return null;
    }
  }
}
