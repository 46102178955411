import { CreateServiceRequestDTO, ServiceResponseDTO } from 'typings/dto/service';
import ModelActionsService from '../ModelActionsService';
import IServiceActionsService from './IServiceActionsService';
import { batch as reduxBatch } from 'react-redux';

export default class ServiceActionsService
  extends ModelActionsService<BusinessService, CreateServiceRequestDTO, ServiceResponseDTO>
  implements IServiceActionsService {
  public async getList(dto?: Record<string, any> | null, filter?: LocationSearchObject, customPath?: string): Promise<void> {
    this.storageDispatch(this.modelStorageActions.startAllLoading());
    await reduxBatch(async () => {
      await this.getListBackground(dto, filter, customPath);
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.stopAllLoading());
    });
  }
}
