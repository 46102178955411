import { Box, Stack, StackProps, Typography } from '@mui/material';
import React from 'react';
import { FileIcon } from 'components/ui/icons';

type Props = StackProps & {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  icon?: React.ReactElement;
};

function EmptyListBlock({ subtitle, icon, children, title, sx, ...restProps }: Props) {
  return (
    <Stack
      sx={{
        mt: (theme) => theme.spacing(8) + ' !important',
        mb: 4,
        color: 'text.secondary',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
      spacing={1}
      {...restProps}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.grey[200],
          borderRadius: 5,
          p: 2,
          pb: 1,
        }}
      >
        {icon || <FileIcon />}
      </Box>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="body2">{subtitle}</Typography>
      <Box sx={{ pt: 1 }}>{children}</Box>
    </Stack>
  );
}

export default React.memo(EmptyListBlock, () => true);
