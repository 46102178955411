import { useEffect } from "react"
import useFirstRender from "./useFirstRender"

type Params = {
  delay: number,
  action: () => any,
  dependencies: any[],
  instantRequest?: boolean
}

export default function useDebounce({ delay, action, dependencies, instantRequest = false }: Params) {
  const isFirstRender = useFirstRender()
  useEffect(() => {
    if (!instantRequest && isFirstRender) return
    let timeoutId = setTimeout(action, delay)
    return () => {
      clearTimeout(timeoutId)
    }
  }, dependencies)
}
