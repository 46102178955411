import { Button, Stack, Typography } from '@mui/material';
import ServiceOrderedSessionListInfoBlock from 'components/_dashboardPagesFeatures/order/add/session/services/ServiceOrderedSessionListInfoBlock';
import ServiceOrderedSessionTotalBlock from 'components/_dashboardPagesFeatures/order/add/session/services/ServiceOrderedSessionTotalBlock';
import ServiceOrderedSessionAddBlock from 'components/_dashboardPagesFeatures/order/add/session/services/add/ServiceOrderedSessionAddBlock';
import OrderRecommendationsListBlock from 'components/_dashboardPagesFeatures/order/add/session/services/recommendation/OrderRecommendationsListBlock';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import PopupAction from 'components/ui/popups/PopupAction';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import { useFormik } from 'formik';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import { ChildFormsState } from 'hooks/useChildForms';
import useDI from 'hooks/useDI';
import useFilterDataInitialize from 'hooks/useFilterDataInitialize';
import useLocales from 'hooks/useLocales';
import useRequestHandler from 'hooks/useRequestHandler';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import IPermissionService from 'services/permission/IPermissionService';
import { RoutingServiceDashboard } from 'services/routing/RoutingService';
import { useSelector } from 'storage';
import { ItemRecommendationDTO } from 'typings/dto/recommendationsConfig';
import { CreateServiceOrderedRequestDTO } from 'typings/dto/serviceOrdered';
import MODEL_NAME from 'typings/models/_model.enum';
import { RECOMMENDATION_STATUS } from 'typings/models/order/order.enum';
import { DEFAULT_ORDER_VIEW_NAME } from 'typings/models/order/orderView.enum';
import StringUtils from 'utils/String';
import * as Yup from 'yup';
import { PRICE_VALUE_TYPE } from '../../../../../typings/models/price.enum';

type Props = {
  orderSession: OrderContractSessionData;
  enterprise: Enterprise;
  cancelHandler: VoidFunction;
  permissionConfig: IPermissionService.PermissionConfigs;
  cancelSessionHandler: VoidFunction;
  recommendations: ItemRecommendationDTO[];
  setRecommendations: Dispatch<SetStateAction<ItemRecommendationDTO[]>>;
  recommendationsChildForms: ChildFormsState;
  addBlockOpened: boolean;
  setAddBlockOpened: (opened: boolean) => void;
};

function OrderAddFromSessionServicesDataForm({
  orderSession,
  permissionConfig,
  cancelHandler,
  cancelSessionHandler,
  recommendations,
  setRecommendations,
  recommendationsChildForms,
  addBlockOpened,
  setAddBlockOpened,
}: Props) {
  const { storageActions } = useDI();
  const { currentUser, permissions } = useSelector((state) => state.auth);
  const [initialOrderType] = useState<OrderType | null>(null);
  const [submitBlockOpened, setSubmitBlockOpened] = useState(false);
  const handleFormErrors = useSnackbarErrorHandler();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const snackbarErrorHandler = useSnackbarErrorHandler();
  const [isCancelBlockOpened, setIsCancelBlockOpened] = useState(false);

  const { state: priceOptionsState } = useRequestHandler({
    initialValue: { customServicesCommission: 0, prices: [] },
    action: () =>
      storageActions.models.priceAny.getPricesForOrderSession({
        regionId: orderSession.region?.id || '',
        brandId: orderSession.brand?.id || '',
        orderId: orderSession.id,
      }),
    instantRequest: true,
    actionName: 'priceRequest',
  });
  const getRecommendations = async () => {
    const records = (await storageActions.models.recommendationsConfig.getRecommendationsForSession(orderSession?.id)).recommendations;
    const toSaveDTOs: Array<CreateServiceOrderedRequestDTO> = records
      .filter((i) => {
        const isPresent = orderSession.services.some((j) => j.technicalName === i.itemKey);

        if (isPresent) {
          return false;
        }
        return i.status === RECOMMENDATION_STATUS.required || i.status === RECOMMENDATION_STATUS.recommendedDefaultOn;
      })
      .map((i) => {
        const price = priceOptionsState.prices.find((s) => s.service.technicalName === i.itemKey);
        if (price) {
          let priceForProductPricePercent = 0;
          if (price.clientValue && price.clientValue.value && price.clientValue.type === PRICE_VALUE_TYPE.percent && price.productPrice) {
            priceForProductPricePercent = price.clientValue.value * price.productPrice * 0.01;
          } else if (price.clientValue.type === PRICE_VALUE_TYPE.percent) {
            return null;
          }
          const dto: CreateServiceOrderedRequestDTO = {
            name: price.name,
            custom: false,
            paid: false,
            quantity: i.quantity,
            includedText: price.service.included,
            notIncludedText: price.service.notIncluded,
            technicalName: price ? price.service.technicalName : StringUtils.generateUUIDv4(),
            price: {
              currency: price.currency,
              discount: { type: 'PERCENT', value: 0 },
              value:
                price.clientValue && price.clientValue.type !== PRICE_VALUE_TYPE.percent ? price.clientPrice : priceForProductPricePercent,
              catalogPrice: price.clientValue,
            },
            productPrice: price.clientValue?.type !== PRICE_VALUE_TYPE.percent ? null : price.productPrice || null,
            recommendationStatus: i.status,
            catalogId: price.service ? price.service.id : undefined,
            orderId: orderSession.id,
            workEstimation: price.service.workEstimation,
            linkedEntities: [],
            promotion: price ? price.promotion : undefined,
          };

          return dto;
        }
        return null;
      })
      .filter((t): t is CreateServiceOrderedRequestDTO => t !== null);

    const items: ItemRecommendationDTO[] = records.filter((i) => {
      const isPresent = orderSession.services.some((j) => j.technicalName === i.itemKey);
      if (isPresent) {
        return false;
      }
      const isRequired = i.status === RECOMMENDATION_STATUS.required || i.status === RECOMMENDATION_STATUS.recommendedDefaultOn;
      return !isRequired;
    });

    items.forEach((i) => {
      recommendationsChildForms.formsMapRef.current.set(i.itemKey, null);
    });
    setRecommendations(items);

    if (toSaveDTOs.length > 0) {
      await storageActions.models.serviceOrdered.createMany(
        { items: toSaveDTOs },
        PATH_BACKEND.orderSession.root + '/' + orderSession.id + '/' + PATH_BACKEND_PART.service.multiple
      );
    }

    await storageActions.models.orderSession.getOrderSession(orderSession.id);
  };

  const [validationSchema, initialValues] = useMemo(() => {
    const validationSchema = Yup.object().shape({});
    const initialValues = {};
    return [validationSchema, initialValues];
  }, [initialOrderType?.id]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const orderId = await storageActions.models.orderSession.createOrderFromSession(orderSession.id);
        navigate(
          RoutingServiceDashboard.order.service.getListPath({
            orderViewTechName: DEFAULT_ORDER_VIEW_NAME.all,
            orderId: orderId,
          })
        );
        setSubmitBlockOpened(false);
      } catch (error) {
        handleFormErrors({ error, callback: () => setSubmitting(false) });
      }
    },
  });

  const tenantId = currentUser?.tenant.id || '';

  useFilterDataInitialize({
    [MODEL_NAME.enterpriseDepartment]: { tenantId },
  });

  const submitHandlerNoNavigate = async (dto: CreateServiceOrderedRequestDTO) => {
    try {
      await storageActions.models.serviceOrdered.create(
        dto,
        PATH_BACKEND.orderSession.root + '/' + dto.orderId + '/' + PATH_BACKEND_PART.service.root
      );

      //  await storageActions.models.orderSession.getOrderSession(orderSession.id);
      // rerenderPageCallback();
    } catch (error) {
      snackbarErrorHandler({ error });
    }
  };

  const submitHandlerRecommendation = async (dto: CreateServiceOrderedRequestDTO) => {
    try {
      await storageActions.models.serviceOrdered.create(
        dto,
        PATH_BACKEND.orderSession.root + '/' + dto.orderId + '/' + PATH_BACKEND_PART.service.root
      );
      if (recommendations.length > 0) {
        const newRecommendations = [...recommendations].filter((s) => s.itemKey !== dto.technicalName);

        setRecommendations(newRecommendations);
      }
      await storageActions.models.orderSession.getOrderSession(orderSession.id);
      // rerenderPageCallback();
    } catch (error) {
      snackbarErrorHandler({ error });
    }
  };
  const submitAllRecommendationForms = async () => {
    const formList = await recommendationsChildForms.submitForms<CreateServiceOrderedRequestDTO>();
    try {
      const items = formList.filter(f => Boolean(f))
      const path = PATH_BACKEND.orderSession.root + '/' + orderSession.id + '/' + PATH_BACKEND_PART.service.multiple
      if (!Boolean(items.length)) {
        return
      }
      await storageActions.models.serviceOrdered.createMany({ items }, path);

      if (recommendations.length > 0) {
        const newRecommendations = [...recommendations].filter((s) => !formList.some((f) => f?.technicalName === s.itemKey));

        setRecommendations(newRecommendations);
      }
      await storageActions.models.orderSession.getOrderSession(orderSession.id);
      // rerenderPageCallback();
    } catch (error) {
      snackbarErrorHandler({ error });
    }
  };

  const canAddServices = useMemo(() => permissions.can(MODEL_PERMISSION.CAN_CREATE_ORDER_SERVICE), []);

  return (
    <Stack spacing={3}>
      {!addBlockOpened && (
        <>
          <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="baseline">
            <Stack direction="row" spacing={3}>
              <Typography variant="h6">{translate('pages.orderAdd.servicesTitle')}</Typography>
            </Stack>
            <Stack spacing={3} direction="row">
              <Button variant="outlined" color="primary" onClick={getRecommendations}>
                {translate('pages.orderAdd.getRecommendations')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setAddBlockOpened(true);
                }}
              >
                {translate('pages.orderAdd.addServiceFromCatalog')}
              </Button>
            </Stack>
          </Stack>

          <ServiceOrderedSessionListInfoBlock permissionConfig={permissionConfig} serviceOrderedList={orderSession.services} />
          {orderSession.services.length > 0 && (
            <ServiceOrderedSessionTotalBlock services={orderSession.services} orderSession={orderSession} />
          )}

          {recommendations && recommendations.length > 0 && (
            <OrderRecommendationsListBlock
              servicesFromCatalog={priceOptionsState.prices}
              recommendations={recommendations}
              orderSessionId={orderSession.id}
              submitHandler={submitHandlerRecommendation}
              formsStatesMap={recommendationsChildForms.formsMapRef.current}
              formsKeys={recommendationsChildForms.formsKeys}
              submitAllRecommendationForms={submitAllRecommendationForms}
            />
          )}
          <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="baseline">
            <ConfirmButtonGroup
              submitHandler={() => {
                setSubmitBlockOpened(true);
              }}
              cancelText={translate('buttons.back')}
              cancelHandler={cancelHandler}
              isLoading={formState.isSubmitting}
            />
            <Button variant="outlined" color="error" onClick={() => setIsCancelBlockOpened(true)}>
              {translate('buttons.cancel')}
            </Button>
          </Stack>
          <PopupAction
            title={translate(`pages.orderAdd.cancelSessionTitle`)}
            isOpened={isCancelBlockOpened}
            actionHandler={cancelSessionHandler}
            closeHandler={() => setIsCancelBlockOpened(false)}
          >
            {translate('pages.orderAdd.cancelSessionText')}
          </PopupAction>
        </>
      )}

      {addBlockOpened && canAddServices && orderSession.region && orderSession.brand && (
        <>
          <ServiceOrderedSessionAddBlock
            orderSession={orderSession}
            regionId={orderSession.region.id}
            brandId={orderSession.brand.id}
            currency={orderSession.currency}
            submitHandler={submitHandlerNoNavigate}
            submitHandlerNoNavigate={submitHandlerNoNavigate}
            permissionConfig={permissionConfig}
            closeAddBlock={() => {
              setAddBlockOpened(false);
              storageActions.models.orderSession.getOrderSession(orderSession.id);
            }}
          />
        </>
      )}

      {submitBlockOpened && !formState.isSubmitting && (
        <PopupAction
          title={translate(`pages.orderAdd.createOrderFromSessionTitle`)}
          isOpened={submitBlockOpened}
          actionHandler={formState.submitForm}
          closeHandler={() => {
            setSubmitBlockOpened(false);
          }}
        >
          {translate(`pages.orderAdd.createOrderFromSessionText`)}
        </PopupAction>
      )}
    </Stack>
  );
}

export default OrderAddFromSessionServicesDataForm;
