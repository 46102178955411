import DIContainer from 'services/DIContainer';
import { OrderContractSessionDataRequestDTO, OrderContractSessionDataResponseDTO } from 'typings/dto/order';
import { ORDER_TYPE_ICON_NAME } from 'typings/models/order/orderType.enum';
import Mapper from './Mapper';
import DateWithTimezone from 'utils/implementations/DateWithTimezone';
import DateUtils from 'utils/Date';

export default class OrderSessionMapper extends Mapper<
  OrderContractSessionData,
  OrderContractSessionDataResponseDTO,
  OrderContractSessionDataRequestDTO
> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers, private dateUtils: DateUtils) {
    super(
      {
        number: 'number',
        type: 'type',
        address: 'address',
        shop: 'shop',
        status: 'status',
        region: 'region',
        legalEntity: 'legalEntity',
        orderSessionType: 'orderSessionType',
        customer: 'customer',
        serviceItems: 'services',
        contracts: 'contracts',
        customFields: 'customFields',
        tables: 'tables',
        brand: 'brand',
        currency: 'currency',
        tenant: 'tenant',
        linkedOrderId: 'linkedOrderId',
        apartments: 'apartments',
      },
      null
    );
  }

  public responseDTOToModel = (dto: OrderContractSessionDataResponseDTO): OrderContractSessionData => {
    const outerMappers = this.getOuterMappers();
    const timezone = dto.region ? dto.region.timezone : this.dateUtils.getCurrentTimezone();

    const orderType:
      | (EntityWithTenant & {
          logoId: ORDER_TYPE_ICON_NAME;
        })
      | undefined = dto.type
      ? {
          ...dto.type,
          technicalName: dto.type.technicalName,
          tenant: dto.type.tenant,
          logoId: dto.type.logoId,
        }
      : undefined;
    const customer = {
      phones: dto.customer.phones.map(this.subEntitiesMappers.phone.responseDTOToModel),
      name: dto.customer.name,
      technicalName: dto.customer.technicalName,
      balance: dto.customer.balance,
      email: dto.customer.email,
      passport: dto.customer.passport
        ? {
            ...dto.customer.passport,
          }
        : undefined,
      registrationAddress: dto.customer.registrationAddress,
      vip: dto.customer.vip,
      additionalContactInfo: dto.customer.additionalContactInfo,
      customerLegalEntity: dto.customer.customerLegalEntity,
    };
    const status = {
      technicalName: dto.status.value,
      isProblematic: dto.status.problematic,
      isWorkStarted: dto.status.onTheJob,
      isWorkInProgress: dto.status.workInProgress,
      completionState: dto.status.completionState,
    };
    const customFieldMapper = this.subEntitiesMappers.orderCustomField;
    const customFields = Object.keys(dto.customFields).reduce((acc, technicalName) => {
      acc[technicalName] = customFieldMapper.responseDTOToModel(dto.customFields[technicalName], outerMappers);
      return acc;
    }, {} as Order['customFields']);

    const services = dto.serviceItems.map(outerMappers.serviceOrdered.responseDTOToModel);

    const contracts = dto.contracts.map((contract) => {
      return {
        ...contract,
        readinessDate: contract.readinessDate ? new DateWithTimezone(contract.readinessDate, timezone, this.dateUtils) : undefined,
        warehouseShippingDate: contract.warehouseShippingDate
          ? new DateWithTimezone(contract.warehouseShippingDate, timezone, this.dateUtils)
          : undefined,
        finalSketch: contract.finalSketch ? contract.finalSketch.map((s) => this.subEntitiesMappers.file.responseDTOToModel_temp(s)) : [],
      };
    });

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      contracts,
      customer,
      status,
      address: dto.address ? this.subEntitiesMappers.address.responseDTOToModel(dto.address) : undefined,
      type: orderType,
      customFields,
      tables: dto.tables ? dto.tables.map(outerMappers.orderTable.responseDTOToModel) : undefined,
      services,
    };
  };
}
