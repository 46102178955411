import { Link, Stack, TableCell, TableRow } from '@mui/material';
import TableHeaderCell from 'components/ui/tables/TableHeaderCell';
import TableVariant1 from 'components/ui/tables/TableVariant1';
import useLocales from 'hooks/useLocales';
import React, { useMemo } from 'react';
import { ORDER_TABLE_VALUE_TYPE } from 'typings/models/order/orderTable.enum';

type OrderTablesListBlockProps = {
  orderId: string;
  orderUpdatedAt: Date;
  tables: OrderTable[];
};

type OrderTablesInfoBlockProps = {
  orderId: string;
  orderUpdatedAt: Date;
  table: OrderTable;
};

function OrderTablesListBlock({ tables, orderUpdatedAt, orderId }: OrderTablesListBlockProps) {
  return (
    <Stack spacing={3}>
      {tables.map((table, tableIndex) => (
        <OrderTablesInfoBlock key={tableIndex} table={table} orderUpdatedAt={orderUpdatedAt} orderId={orderId} />
      ))}
    </Stack>
  );
}

export default React.memo(OrderTablesListBlock, (pp, np) => pp.orderId === np.orderId && pp.orderUpdatedAt === np.orderUpdatedAt);

type RowType = {
  value: string;
  type: ORDER_TABLE_VALUE_TYPE;
};

function OrderTablesInfoBlock({ table, orderId, orderUpdatedAt }: OrderTablesInfoBlockProps) {
  const { translate } = useLocales();

  const [columns, rows] = useMemo(() => {
    const columns = Object.values(table.columns);
    const rows = table.records.map((record) => {
      const row: Record<string, RowType> = {};
      record.values.forEach((value) => {
        row[table.columns[value.columnId]] = { value: value.value, type: value.type };
      });
      return row;
    });
    return [columns, rows];
  }, [orderId, orderUpdatedAt.getTime()]);

  return (
    <TableVariant1 header={<TableHeader columns={columns} />} sx={{ mt: 0 }}>
      {rows.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          {columns.map((column, columnIndex) => (
            <TableCell key={columnIndex}>
              {/* TODO пока ORDER_TABLE_VALUE_TYPE содержит всего 2 типа, определяем контент тут, но если типов будет больше нужно вынести в утилиту и проверять по switch case */}
              {row[column].type === ORDER_TABLE_VALUE_TYPE.URL ? (
                <Link href={row[column].value} target="_blank">
                  {translate('entities.order.orderTable.fileTitle')}
                </Link>
              ) : (
                row[column].value
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableVariant1>
  );
}

function TableHeader({ columns }: { columns: string[] }) {
  return (
    <TableRow>
      {columns.map((column, columnIndex) => {
        return <TableHeaderCell key={columnIndex}>{column}</TableHeaderCell>;
      })}
    </TableRow>
  );
}
