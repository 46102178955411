import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import Label from 'components/ui/info/Label';
import useLocales from 'hooks/useLocales';

type Props = {
  discountsBlock: Array<{
    discountValue: string;
    discountDescriprion: string;
  }>;
  labelColor: 'default' | 'warning';
  sx?: SxProps<Theme>;
};

export function ServiceOrderedDiscountInfoBlock({ discountsBlock, labelColor, sx }: Props) {
  const { translate } = useLocales();

  return (
    <Stack spacing={2} sx={{ mt: 3, mb: 4, ...sx }}>
      <Typography variant="subtitle2">{translate('pages.orderServiceList.promotions')}</Typography>

      {discountsBlock.map((discount, index) => {
        return (
          <Box key={index}>
            <Label key={index} color={labelColor} sx={{ mr: 1 }}>
              {discount.discountValue}
            </Label>
            <Typography variant="body2" sx={{ display: 'inline-block' }}>
              {discount.discountDescriprion}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
}
