import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD as P, PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';
import NavigateFrontendUtils from 'utils/NavigateFrontend';

export default class CalendarRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(P.calendar.root);


  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.calendar> = (_params, _permissions, translate) => {
    return [{ name: translate('menus.main.calendar'), href: this.root }];
  };
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getOrderPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, PATH_PARAM_DASHBOARD.order.main, PAGE_TYPE.info);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  }
  getOrderAddLinkPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params) => this.getDBPath(P.order.root, PAGE_TYPE.add, params.orderId, PATH_PARAM_DASHBOARD.order.linked);
  getOrderComplaintPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params) =>
    this.getDBPath(P.order.root, PAGE_TYPE.add, params.orderId, PATH_PARAM_DASHBOARD.order.complaint);


  getAddBc = this.getInstanceBc;
  getInfoBc = this.getEmptyBreadcrumbs;

  public order = {
    service: new ServiceRoutes(),
    report: new ReportRoutes(),
    review: new ReviewRoutes(),
    task: new TaskRoutes(),
    history: new HistoryRoutes(),
    comment: new CommentRoutes(),
    providerTransactionsData: new ProviderTransactionsDataRoutes(),
    document: new DocumentRoutes(),
    goodInformation: new GoodInformationRoutes(),
    getInfoPath: this.getOrderPath,
    getAddLinkPath: this.getOrderAddLinkPath,
    getAddComplaintPath: this.getOrderComplaintPath,
  }
}

class ServiceRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.services, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.services, PAGE_TYPE.new, PAGE_TYPE.add);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getEditBc = this.getEmptyBreadcrumbs;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.services, params.serviceId, PAGE_TYPE.edit);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class ReportRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.report, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.report, PAGE_TYPE.new, PAGE_TYPE.add);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getEditBc = this.getEmptyBreadcrumbs;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.report, params.reportId, PAGE_TYPE.edit);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class ReviewRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getInfoBc = this.getEmptyBreadcrumbs;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.review, PAGE_TYPE.info);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.review, PAGE_TYPE.add);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getEditBc = this.getEmptyBreadcrumbs;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.review, PAGE_TYPE.edit);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class TaskRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.tasks, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.tasks, PAGE_TYPE.new, PAGE_TYPE.add);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getEditBc = this.getEmptyBreadcrumbs;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.tasks, params.taskId, PAGE_TYPE.edit);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class ProviderTransactionsDataRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.providerTransactions, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };

  getAddBc = this.getEmptyBreadcrumbs;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.providerTransactions, PAGE_TYPE.new, PAGE_TYPE.add);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class HistoryRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.history, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class CommentRoutes extends FrontendRoutesBase {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.comments, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class DocumentRoutes extends FrontendRoutesBase {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.documents, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}

class GoodInformationRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.calendar> = (params, includeSearch = true) => {
    const path = this.getDBPath(P.calendar.root, params.viewType, PATH_PARAM_DASHBOARD.calendar.order, params.orderId, P.order.goodInformation, PAGE_TYPE.list);
    return includeSearch ? NavigateFrontendUtils.createURLWithCurrentSearchQuery(path) : path;
  };
}