import React from 'react';
import ModelUtils from 'utils/models/ModelUtils';
import { Stack, Typography } from '@mui/material';
import useDI from 'hooks/useDI';
import Label from 'components/ui/info/Label';

function ServiceOrderedClientPriceBlock({ service, isMobile = false }: { service: ServiceOrdered, isMobile?: boolean }) {
  const { statefulUtils, services } = useDI();
  const { translate } = services.language;
  const manualDiscount = service.price.discount.value > 0;
  const promotionDiscount = Boolean(service.promotion?.discounts.length);
  const isHasAnyDiscount = manualDiscount || promotionDiscount;
  const initialClientPriceValue = service.promotion?.priceBefore || service.price.initialValue;
  const totalDiscount =
    service.price.initialValue * service.quantity -
    service.price.totalClientValue +
    ((service.promotion?.priceBefore || 0) - (service.promotion?.priceAfter || 0)) * service.quantity;


  if (isMobile) {
    return (
      <Stack spacing={0.5}>
        <Stack direction='row' spacing={1}>
          <Stack>
            <Typography fontWeight={500} variant="caption">{statefulUtils.money.toString(service.price.totalClientValue, service.price.currency)} </Typography>
          </Stack>
          {isHasAnyDiscount && (
            <Typography variant='caption' color="text.secondary" sx={{ textDecoration: 'line-through' }}>
              {statefulUtils.money.toString(initialClientPriceValue * service.quantity, service.price.currency)}
            </Typography>
          )}
        </Stack>
        {isHasAnyDiscount && (
          <>
            <Label variant="ghost" sx={{ alignSelf: 'flex-start' }}>
              {translate('entities.service.discount')} {promotionDiscount ? totalDiscount : service.price.discount.value}
              {manualDiscount &&
                !promotionDiscount &&
                (service.price.discount.type === 'PERCENT' ? '%' : services.language.getCurrencySymbol(service.price.currency))}
              {promotionDiscount && services.language.getCurrencySymbol(service.price.currency)}
            </Label>
          </>
        )}
      </Stack>
    )
  }

  return (
    <Stack width={'100px'}>
      <Typography variant="inherit">{statefulUtils.money.toString(service.price.totalClientValue, service.price.currency)} </Typography>
      {service.quantity > 1 && (
        <Typography variant="body2" color="text.secondary">
          {statefulUtils.money.toString(initialClientPriceValue, service.price.currency)} / {translate('common.piece')}
        </Typography>
      )}
      {isHasAnyDiscount && (
        <>
          <Typography color="text.secondary" sx={{ textDecoration: 'line-through', mt: 1 }}>
            {statefulUtils.money.toString(initialClientPriceValue * service.quantity, service.price.currency)}
          </Typography>
          <Label variant="ghost" sx={{ alignSelf: 'flex-start' }}>
            {translate('entities.service.discount')} {promotionDiscount ? totalDiscount : service.price.discount.value}
            {manualDiscount &&
              !promotionDiscount &&
              (service.price.discount.type === 'PERCENT' ? '%' : services.language.getCurrencySymbol(service.price.currency))}
            {promotionDiscount && services.language.getCurrencySymbol(service.price.currency)}
          </Label>
        </>
      )}
    </Stack>
  );
}

export default React.memo(ServiceOrderedClientPriceBlock,
  (pp, np) => ModelUtils.checkEquality(pp.service, np.service) && pp.service.quantity === np.service.quantity
);
