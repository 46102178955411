import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
import { APP_NAME } from 'configs/vars';

// ----------------------------------------------------------------------

type Props = BoxProps & {
  disabledLink?: boolean;
};

export default function Logo({ disabledLink = false, sx, ...restProps }: Props) {
  const logo = (
    <Box sx={{ width: 80, height: 40, display: 'flex', justifyContent: 'stretch', alignContent: 'center', ...sx }} {...restProps}>
      <img src="/logos/logo_single.svg" alt={APP_NAME} width="100%" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
