export enum CALENDAR_VIEW_TYPE {
  appointment = 'appointment',
  browsing = 'browsing'
}

export enum CALENDAR_VIEWS {
  day = 'resourceTimelineDay',
  week = 'resourceTimelineWeek'
}

export enum CALENDAR_TECHNICIAN_VIEWS {
  day = 'timeGridDay',
  week = 'timeGridWeek',
  list = 'listMonth'
}